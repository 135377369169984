<template>
  <v-container fluid class="pa-4" id="fullscreen-wrapper">

    <!-- TOOLBAR ITEMS -->
    <portal to="toolbar_right" :order="2">
      <MenuTooltip
        :menu-attrs="{ bottom: true, left: true,  closeOnContentClick: false }"
        :btn-attrs="{ icon: true }"
        icon="mdi-dots-vertical"
        tooltip="Layout"
      >
        <v-card style="width: 20rem">
          <v-card-text>
            <v-checkbox
              v-model="keepViewState"
              label="Keep view state in memory"
              class="ma-0 pa-0"
              hide-details
            />
          </v-card-text>
        </v-card>
      </MenuTooltip>
    </portal>

    <v-expansion-panels
      v-model="panelList"
      class="header-unclickable"
      multiple
      readonly
    >

      <!-- PROJECT -->
      <v-expansion-panel>
        <v-expansion-panel-header ref="project_panel" expand-icon="mdi-menu-down">
          Project

          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('project')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex align-start" style="gap: 1rem">
            <ProjectAutocomplete
              v-model="projectId"
              :items.sync="projectList"
              :filters="[{field: 'status', operator: 'equals', value: ['new', 'progress']}]"
              :rules="[!projectId || !!mode || 'You must choose a mode first']"
              :auto-focus="!showModeSelection"
              hide-details="auto"
              outlined
              clearable
              @change="onProjectChange"
              @load="onProjectLoad"
            />

            <v-autocomplete
              v-if="showModeSelection"
              v-model="mode"
              :label="$t('reviewView.mode')"
              :items="modeList"
              :rules="[!!mode || '']"
              auto-focus
              prepend-inner-icon="mdi-account"
              style="max-width: 15rem"
              hide-details="auto"
              outlined
              return-object
              @input="onModeChange"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- ADVANCED SEARCH -->
      <v-expansion-panel v-if="ready" v-show="showFilters">
        <v-expansion-panel-header ref="filters_panel" expand-icon="mdi-menu-down">
          <div class="d-flex align-center justify-space-between w-100" style="gap: 1rem">
            <div class="text-truncate" style="flex: 1">
              <span>Advanced Search Builder</span>
            </div>

            <span v-if="queryLocked && $vuetify.breakpoint.lgAndUp" class="warning--text">This query is currently locked to prevent accidental modifications.</span>

            <v-tooltip bottom>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  v-model="queryLocked"
                  :color="queryLocked ? 'warning' : null"
                  icon
                  @click="queryLocked = !queryLocked"
                >
                  <v-icon>mdi-lock</v-icon>
                </v-btn>
              </template>
              <span v-if="queryLocked">Unlock Query</span>
              <span v-else>Lock Query</span>
            </v-tooltip>
            <PresetManager
              ref="presetManager"
              v-if="projectId"
              :value="filterList"
              :default-item="defaultPresetItem"
              :loading="loadingPresets"
              :saving="savingPreset"
              :default-item-args="{
                projectId,
              }"
              :load-callback="loadPresets"
              :save-callback="savePresets"
              :remove-callback="removePreset"
              id="review_filters"
              label="Presets"
              hide-details="auto"
              outlined
              dense
              clearable
              style="width: 25rem; max-width: 35rem"
              class="mr-4"
              @input="onApplyPresetFilters"
            />
          </div>

          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('filters')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <!-- BUILDER -->
          <v-row>
            <v-col cols="12">
              <ProjectQueryBuilder
                v-if="ready"
                ref="builderComponent"
                v-model="filterList"
                :project-id="projectId"
                :mode="mode"
                :advanced="advanced"
                :project="currentProject"
                :keywords="keywordList"
                :tags="tagList"
                :disabled="queryLocked"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- QUERY -->
      <v-expansion-panel v-if="ready" v-show="showQuery">
        <v-expansion-panel-header ref="query_panel" expand-icon="mdi-menu-down">
          Query

          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('query')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <v-form v-model="formQueryValid">

            <!-- ADVANCED -->
            <v-row>
              <v-col cols="12" md="4" lg="2">
                <v-autocomplete
                  v-model="advanced.stage"
                  :items="stageList"
                  label="Stage"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                  @click:clear="() => onStageClear()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" lg="2" style="position: relative">
                <v-autocomplete
                  v-model="advanced.status"
                  :items="statusList"
                  :disabled="!advanced.stage"
                  :label="getOfficialStatusStageLabel()"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  v-model="advanced.reviewed"
                  :items="reviewedByList"
                  :disabled="!advanced.stage"
                  :label="getReviewedByStageLabel()"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="9" lg="3" class="d-flex align-center" style="gap: 1rem">
                <v-autocomplete
                  v-model="advanced.order"
                  :items="orderList"
                  label="Sort by"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                  multiple
                >
                  <template #selection="{ item, index }">
                    <div :tick="tick" class="d-flex align-center mr-3" style="gap: 0.5rem">
                      <span>{{ item.text }}</span>
                      <v-btn outlined x-small @click.stop="onToggleSort(index)">
                        <v-icon v-if="advanced.orderAsc[index]" small>mdi-sort-ascending</v-icon>
                        <v-icon v-else small>mdi-sort-descending</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-combobox
                  v-model="limit"
                  :items="limitList"
                  label="Limit"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                >
                </v-combobox>
              </v-col>
            </v-row>

            <export-component
              v-if="currentProject"
              ref="exportComponent"
              :options="exportCsvModal"
              :project="currentProject"
              @export-csv="timeoutCallback(() => exportCsv())"
            />

            <!-- ACTION BUTTONS -->
            <div class="d-flex flex-wrap align-center mt-4" style="gap: 1rem">
              <v-btn
                :disabled="!formQueryValid || recordListLoading"
                :loading="recordListLoading"
                color="primary"
                style="flex: 2"
                @click="timeoutCallback(() => search())"
              >
                <v-icon left>
                  mdi-magnify
                </v-icon>
                Show records
              </v-btn>

              <v-btn
                :disabled="!formQueryValid || recordListLoading"
                color="default"
                outlined
                style="flex: 2"
                @click="timeoutCallback(() => searchMetrics())"
              >
                <v-icon left>
                  mdi-chart-bar-stacked
                </v-icon>
                Show Metrics
              </v-btn>

              <v-btn
                v-if="mode && ['arbitrator', 'leader'].includes(mode.value)"
                :disabled="!formQueryValid || recordListLoading"
                color="default"
                outlined
                style="flex: 2"
                @click="timeoutCallback(() => exportComponent.open())"
              >
                <v-icon left>
                  mdi-export
                </v-icon>
                Export
              </v-btn>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- METRICS -->
      <v-expansion-panel v-if="ready" v-show="showMetrics">
        <v-expansion-panel-header ref="metrics_panel" expand-icon="mdi-menu-down">
          Metrics

          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('metrics')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <!-- USER SELECTION -->
          <v-row v-if="mode && ['arbitrator', 'leader'].includes(mode.value)" class="mb-4">
            <v-col cols="12">
              <user-autocomplete-component
                v-model="metricFilters.users"
                :project-id="projectId"
                label="Users"
                multiple
                outlined
                dense
                return-object
                @change="() => searchMetrics(false, metricFilters.users)"
              />
            </v-col>
          </v-row>

          <metrics-component
            :loading="metricsLoading"
            :metrics="metrics"
            title="Official Metrics"
          >
          </metrics-component>

          <metrics-component
            v-for="(userMetrics, userMetricsIndex) in usersMetrics"
            :key="userMetricsIndex"
            :title="userMetrics.title"
            :loading="userMetrics.loading"
            :metrics="userMetrics.metrics"
            class="mt-12"
          >
          </metrics-component>

        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- KEYWORDS -->
      <v-expansion-panel v-if="ready" v-show="showKeywords">
        <v-expansion-panel-header ref="keywords_panel" expand-icon="mdi-menu-down">
          <div class="d-flex align-center justify-space-between mr-3" style="gap: 1rem">
            Keywords ({{ availableKeywordList.filter(keyword => keyword.data.id).length }})

            <div class="d-flex align-center" style="gap: 0.5rem">
              <v-btn
                v-if="availableKeywordList.length > 1"
                text
                small
                @click="onSelectAllKeywordsClick"
              >
                <v-icon left>
                  <template v-if="availableKeywordAllSelected">mdi-checkbox-marked-outline</template>
                  <template v-else>mdi-checkbox-blank-outline</template>
                </v-icon>
                <span v-text="$t('btn.selectAll')"></span>
              </v-btn>

              <v-btn text small @click="onAddKeywordClick">
                <v-icon left>mdi-plus</v-icon>
                <span>Add new keyword</span>
              </v-btn>
            </div>
          </div>
          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('keywords')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager><!-- NEEDS TO BE EAGER LOADED BECAUSE OF onAddKeywordClick -->
          <div class="text-center">
            <v-progress-linear
              v-if="keywordLoading"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-alert
              v-else-if="!hasKeywords"
              border="bottom"
              colored-border
              type="info"
              color="primary"
              elevation="2"
            >
              No keyword found
            </v-alert>
          </div>
          <v-row v-if="hasKeywords" no-gutters>
            <v-col cols="12" class="d-flex align-center flex-wrap" style="gap: 0.5rem">
              <KeywordChip
                v-model="availableKeywordList[keywordIdx]"
                v-for="(keyword, keywordIdx) in availableKeywordList"
                :ref="'keyword_' + keywordIdx"
                :key="keyword.data.id"
                :class="{
                  'd-none': !keyword.data.id
                }"
                selectable
                editable
                deletable
                detailed
                @apply="onApplyKeyword"
                @cancel="() => !keyword.data.id && availableKeywordList.splice(keywordIdx, 1)"
                @deleted="() => availableKeywordList.splice(keywordIdx, 1)"
              />
            </v-col>
            <v-col cols="12" class="mt-4">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="backgroundVeryLight lighten-4 pa-4">
              <div class="d-flex align-center justify-space-between mb-2">
                <v-subheader style="height: auto" class="pl-0">Synonyms</v-subheader>

                <v-menu offset-y max-height="300">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      text
                      small
                    >
                      <span>Add new synonym</span>
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(keyword, keywordIdx) in availableKeywordList.filter(keyword => keyword.data.id)"
                      :key="keywordIdx"
                      @click="() => onAddSynonymClick(keyword, keywordIdx)"
                    >
                      <v-list-item-title>{{ keyword.data.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-divider class="mb-4" />
              <div class="d-flex align-center flex-wrap" style="gap: 0.5rem">
                <template v-for="(keyword, keywordIdx) in availableKeywordList">
                  <template v-for="(synonym, synonymIdx) in keyword.data.synonymlist">
                    <KeywordChip
                      v-show="!synonym.data.deleted"
                      v-model="availableKeywordList[keywordIdx]"
                      :synonym="keyword.data.synonymlist[synonymIdx]"
                      :key="synonym.autoIncrementId"
                      :ref="'keyword_' + keyword.data.id + '_' + synonymIdx"
                      :class="{
                        'd-none': !synonym.data.id
                      }"
                      selectable
                      editable
                      deletable
                      detailed
                      @apply="onApplyKeyword"
                      @cancel="() => !synonym.data.id && keyword.data.synonymlist.splice(synonymIdx, 1)"
                      @deleted="() => keyword.data.synonymlist.splice(synonymIdx, 1)"
                    />
                  </template>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- ELIGIBILITY CRITERIA -->
      <v-expansion-panel v-if="ready" v-show="showCriteria && hasCriteria">
        <v-expansion-panel-header ref="criteria_panel" expand-icon="mdi-menu-down">
          Eligibility Criteria

          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('criteria')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row v-if="hasCriteria" class="background">
              <v-col cols="6">
                <v-card class="fill-height">
                  <v-card-subtitle>Inclusion Criteria</v-card-subtitle>
                  <v-card-text>
                    <v-alert v-if="currentProject?.data.inclusionCriteria.length === 0" type="info" text class="mb-0">No criteria defined yet</v-alert>
                    <ol v-else>
                      <li v-for="(item, itemKey) in currentProject?.data.inclusionCriteria" :key="itemKey" v-text="item"></li>
                    </ol>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="fill-height">
                  <v-card-subtitle>Exclusion Criteria</v-card-subtitle>
                  <v-card-text>
                    <v-alert v-if="currentProject?.data.exclusionCriteria.length === 0" type="info" text class="mb-0">No criteria defined yet</v-alert>
                    <ol v-else>
                      <li v-for="(item, itemKey) in currentProject?.data.exclusionCriteria" :key="itemKey" v-text="item"></li>
                    </ol>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- OPTIONS -->
      <v-expansion-panel v-if="ready" v-show="showOptions">
        <v-expansion-panel-header ref="options_panel" expand-icon="mdi-menu-down">
          Options

          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('options')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="recordOptions.recordFields"
                :items="recordFieldList"
                :label="$t('reviewView.recordFieldList')"
                hide-details="auto"
                multiple
                clearable
                chips
                deletable-chips
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleRecordFields"
                  >
                    <v-list-item-action>
                      <v-icon :color="recordOptions.recordFields.length > 0 ? 'indigo darken-4' : ''">
                        {{ recordFieldsIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <div class="d-flex align-center flex-wrap mt-6" style="gap: 1rem">
            <v-switch
              class="mt-0"
              v-model="recordOptions.highlightKeywords"
              inset
              label="Highlight keywords"
              hide-details="auto"
            ></v-switch>
            <v-switch
              class="mt-0"
              v-model="recordOptions.highlightFilters"
              inset
              label="Highlight filters"
              hide-details="auto"
            ></v-switch>
            <v-switch
              class="mt-0"
              v-model="recordOptions.splitSection"
              inset
              label="Split sections"
              hide-details="auto"
            ></v-switch>
            <v-switch
              class="mt-0"
              v-model="recordOptions.addSpaces"
              inset
              label="Add spaces"
              hide-details="auto"
            ></v-switch>
            <v-switch
              class="mt-0"
              v-model="recordOptions.removeUseless"
              inset
              label="Remove useless"
              hide-details="auto"
            ></v-switch>
          </div>

        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- RECORDS -->
      <v-expansion-panel v-if="ready && currentProject" class="fullscreen-keep" v-show="showRecords">
        <v-expansion-panel-header ref="records_panel" expand-icon="mdi-menu-down">
          <div class="d-flex align-center" style="gap: 0.5rem">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!canCreateRecord()"
                  icon
                  @click="onCreateRecordClick"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span v-text="$t('recordComponent.addRecord')"></span>
            </v-tooltip>

            <span>Records</span>
          </div>

          <template #actions>
            <div class="d-flex align-center">
              <v-btn-toggle v-model="percentageTypeIdx" class="mr-3" color="primary">
                <v-btn x-small>% {{ percentageTypes[0] }}</v-btn>
                <v-btn x-small>% {{ percentageTypes[1] }}</v-btn>
              </v-btn-toggle>

              <!-- FULLSCREEN -->
              <fullscreen-component />

              <div class="d-flex align-center ml-2" style="gap: 1rem">
                <v-btn icon @click="onExpansionPanelHeaderClick('records')">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>

          <div class="text-center">
            <v-progress-linear
              v-if="recordListLoading"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-alert
              v-else-if="!hasRecords"
              outlined
              type="info"
              color="warning"
              class="mb-0"
            >
              No record found
            </v-alert>
          </div>

          <div
            tabindex="0"
          >
            <v-data-iterator
              v-if="hasRecords"
              :items="recordList"
              :page="recordListPage"
              :loading="recordListLoading"
              :items-per-page="itemsPerPage"
              item-key="data.id"
              hide-default-footer
            >
              <template v-slot:header>
                <v-row
                  class="mt-2"
                  align="center"
                  justify="center"
                >

                  <!-- CONFLICT WARNING -->
                  <!--                  <v-col cols="12">-->
                  <!--                    <v-alert-->
                  <!--                      border="bottom"-->
                  <!--                      colored-border-->
                  <!--                      type="error"-->
                  <!--                      color="error"-->
                  <!--                      elevation="2"-->
                  <!--                    >-->
                  <!--                      Too many conflict, you may want to discuss with your colleague regarding this situation.-->
                  <!--                    </v-alert>-->
                  <!--                  </v-col>-->

                  <!-- PROJECT PROGRESS -->
                  <v-col cols="12" class="text-center pt-0">
                    <project-progress-component
                      ref="projectProgressComponent"
                      :stage="projectStage"
                      :type="percentageType"
                      :project-id="projectId"
                      :user-id="userId"
                      :filters="getFilters()"
                      :limit="getLimit()"
                      :advanced="getAdvanced()"
                    />
                  </v-col>

                  <!-- PAGINATION -->
                  <v-btn
                    :disabled="!canGoFirst"
                    fab
                    small
                    text
                    @click="firstPage"
                  >
                    <v-icon>mdi-chevron-double-left</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!canGoPrevious"
                    fab
                    small
                    text
                    @click="previousPage"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>

                  <v-spacer></v-spacer>

                  <span class="mr-4 grey--text d-flex align-center" style="gap: 0.5rem">
                    Page
                    <v-text-field
                      v-model.number="recordListPagePagination"
                      :rules="[rules.minMaxPage]"
                      dense
                      outlined
                      hide-details
                      type="number"
                      class="text-center"
                      style="max-width: 5rem"
                      @input="onPageInput"
                    />
                    of {{ numberOfPages }}
                  </span>

                  <v-spacer></v-spacer>

                  <v-btn
                    :disabled="!canGoNext"
                    fab
                    small
                    text
                    @click="nextPage"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!canGoLast"
                    fab
                    small
                    text
                    @click="lastPage"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </v-btn>
                  <!-- END PAGINATION -->

                </v-row>
              </template>
              <template #default="{ items }">
                <v-row class="background mt-6 pb-1 mb-1">
                  <v-alert
                    v-if="recordListPage === recordList.length"
                    type="warning"
                    class="w-100 mb-0"
                    flat
                    tile
                  >
                    This is the last record of the list.
                  </v-alert>

                  <v-col
                    v-for="record in items"
                    :key="record.data.id"
                    cols="12"
                  >
                    <record-component
                      :v-model="record"
                      :record-list="recordList"
                      :tags="tagList"
                      :index="recordListPagePagination - 1"
                      :project="currentProject"
                      :user-type="mode.value"
                      :stage="advanced.stage || getProject()?.data.stage"
                      :keyword-list="keywordList"
                      :options="recordOptions"
                      :filters="filterList"
                      :side-panel.sync="recordSidePanel"
                      :show-user-list="showUserList"
                      :row-sizes="recordRowSizes"
                      ref="recordComponent"
                      @status="setStatus"
                      @first="firstPage"
                      @last="lastPage"
                      @previous="previousPage"
                      @next="nextPage"
                      @save-status-success="onSaveStatusSuccess"
                      @load-project-progress="loadProjectProgress"
                      @next-page="nextPage"
                      @set-page="setPage"
                      @pid-change="onPidChange"
                      @delete="search"
                    ></record-component>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- PROJECT INFORMATION -->
      <v-expansion-panel v-if="ready" v-show="showProjectInformation">
        <v-expansion-panel-header ref="project_info_panel" expand-icon="mdi-menu-down">
          Project Information

          <template #actions>
            <v-btn icon @click="onExpansionPanelHeaderClick('project_info')">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <h4>Description</h4>
              <div v-html="currentProject?.data.description || 'Not defined yet.'"></div>
            </v-col>
            <v-col cols="12" md="6">

              <!-- OBJECTIVES -->
              <div>
                <v-subheader>Objectives</v-subheader>
                <v-sheet color="background" class="pa-3">
                  <v-expansion-panels multiple>
                    <v-expansion-panel v-for="item in objectiveList" :key="item.key" :disabled="currentProject?.data[item.key].length === 0">
                      <v-expansion-panel-header>
                        {{ item.label }}
                        <div class="text-right">
                          <v-chip v-if="currentProject?.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                          <v-chip v-else color="primary" class="mr-2" small label>{{ currentProject?.data[item.key].length }}</v-chip>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ul>
                          <li
                            v-for="str in currentProject?.data[item.key]"
                            v-html="$options.filters?.nl2br(str)"
                            :key="str"
                          ></li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-sheet>
              </div>

              <!-- CRITERIA -->
              <div class="mt-3">
                <v-subheader>Criteria</v-subheader>
                <v-sheet color="background" class="pa-3">
                  <v-expansion-panels multiple>
                    <v-expansion-panel v-for="item in criteriaList" :key="item.key" :disabled="currentProject?.data[item.key].length === 0">
                      <v-expansion-panel-header>
                        {{ item.label }}
                        <div class="text-right">
                          <v-chip v-if="currentProject?.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                          <v-chip v-else color="primary" class="mr-2" small label>{{ currentProject?.data[item.key].length }}</v-chip>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ul>
                          <li
                            v-for="str in currentProject?.data[item.key]"
                            v-html="$options.filters?.nl2br(str)"
                            :key="str"
                          ></li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-sheet>
              </div>
            </v-col>
            <v-col cols="12" md="6">

              <!-- PICOTS -->
              <div>
                <v-subheader>PICOTS</v-subheader>
                <v-sheet color="background" class="pa-3">
                  <v-expansion-panels multiple>
                    <v-expansion-panel v-for="item in picotList" :key="item.key" :disabled="currentProject?.data[item.key].length === 0">
                      <v-expansion-panel-header>
                        {{ item.label }}
                        <div class="text-right">
                          <v-chip v-if="currentProject?.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                          <v-chip v-else color="primary" class="mr-2" small label>{{ currentProject?.data[item.key].length }}</v-chip>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ul>
                          <li
                            v-for="str in currentProject?.data[item.key]"
                            v-html="$options.filters?.nl2br(str)"
                            :key="str"
                          ></li>
                        </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Watch, Ref} from 'vue-property-decorator';
import { orderList, reviewedByList, statusList, recordFieldList, projectUserTypeList, stageList, objectiveList, picotList, criteriaList } from '@/enums/global';
import Identity from '@/modules/sdk/core/identity';
import Logger from '@/modules/sdk/core/logger';
import Rules from '@/modules/sdk/core/rules';
import UserModel from '@/modules/sdk/models/user.model';
import ProjectModel from '@/models/project.model';
import RecordModel from '@/models/record.model';
import RecordService from '@/services/record.service';
import KeywordModel from '@/models/keyword.model';
import KeywordService from '@/services/keyword.service';
import SynonymModel from '@/models/synonym.model';
import ProjectQueryBuilder from '@/components/ProjectQueryBuilder.vue';
import RecordComponent from '@/views/Admin/Component/RecordComponent.vue';
import MetricsComponent from '@/views/Admin/Component/MetricsComponent.vue';
import ProjectProgressComponent from '@/views/Admin/Component/ProjectProgressComponent.vue';
import ExportComponent from '@/views/Admin/Component/ExportComponent.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import KeywordChip from '@/components/KeywordChip.vue';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';
import ProjectAutocomplete from '@/components/ProjectAutocomplete.vue';
import moment from 'moment';
import {saveAs} from 'file-saver';
import contentDisposition from 'content-disposition';
import UserAutocompleteComponent from '@/views/Admin/Component/UserAutocompleteComponent.vue';
import ProjectFilterStateService from '@/services/project-filter-state.service';
import ProjectFilterStateModel from '@/models/project-filter-state.model';
import FullscreenComponent from '@/views/Admin/Component/Record/FullscreenComponent.vue';
import SynonymService from '@/services/synonym.service';
import Query from '@/modules/sdk/core/query';
import ProjectUserModel from '@/models/project-user.model';
import ProjectService from '@/services/project.service';
import { queryMapper } from '@/utils/query-mapper';
import { IQueryAdvanced } from '@/modules/sdk/core/interfaces';
import { SharedQuery } from '@/utils/shared-query';
import TagModel from '@/models/tag.model';
// @ts-ignore
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete/VAutocomplete'

const d = new Logger('views/Admin/ReviewView');
let recordListPagePaginationChangeTimeout: any;

interface IMetricFilters {
  users: Array<UserModel>,
}

@Component({
  components: {
    UserAutocompleteComponent,
    ExportComponent,
    MetricsComponent,
    RecordComponent,
    ProjectQueryBuilder,
    ProjectProgressComponent,
    PresetManager,
    KeywordChip,
    FullscreenComponent,
    ProjectAutocomplete,
    MenuTooltip,
  }
})
export default class ReviewView extends Vue {
  @Ref() readonly projectProgressComponent!: ProjectProgressComponent;
  @Ref() readonly exportComponent!: ExportComponent;
  @Ref() readonly recordComponent!: Array<RecordComponent>;
  @Ref() readonly builderComponent!: ProjectQueryBuilder;
  @Ref() readonly presetManager!: PresetManager;
  @Ref() readonly projectRef!: VAutocomplete;

  // View & panels definition
  ready = false
  percentageTypeIdx = 0
  percentageTypes = ['project', 'filtered']
  mode: any = null
  view: 'default' | 'metrics' | 'records' = 'default';
  panelList: Array<number> = [0];
  expansionPanel = {
    project: 0,
    filters: 1,
    query: 2,
    metrics: 3,
    keywords: 4,
    criteria: 5,
    options: 6,
    records: 7,
  };

  // Project
  project: ProjectModel | null = null;
  currentProject: ProjectModel | null = null;
  projectId = 0;
  projectList = [];
  queryLocked = false;
  tagList: Array<TagModel> = [];
  stageList = stageList
  objectiveList = objectiveList;
  picotList = picotList;
  criteriaList = criteriaList;

  // Keywords
  keywordList: Array<KeywordModel> = [];
  keywordLoading = false;

  // Metrics
  metrics = {};
  metricsLoading = false;
  usersMetrics: Array<any> = [];
  metricFilters: IMetricFilters = {
    users: [],
  }

  // Records
  recordList: Array<RecordModel> = [];
  recordListPage = 1;
  recordListPagePagination = 1;
  recordListLoading = false;
  recordSidePanel = true;
  recordRowSizes = [
    { md: 7, lg: 8 },
    { md: 5, lg: 4 },
  ];

  // Filters and options
  loadingPresets = false;
  savingPreset = false;
  filterList: Array<any> = [];
  defaultPresetItem: Array<any> = [{
    logic: 'and',
    operator: 'contains',
    field: null,
    value: null,
    group: [] as any,
  }];

  recordFieldList = recordFieldList;
  recordOptions = {
    highlightKeywords: true,
    highlightFilters: true,
    addSpaces: true,
    splitSection: true,
    removeUseless: true,
    recordFields: [] as Array<string>,
  };

  // Confusing now because "advanced" was not the
  // "Advanced Query Builder" at the beginning.
  // Here, "advanced" means the Query
  // (stage/status/reviewed by/etc).
  tick = 0
  advanced: IQueryAdvanced = {
    stage: null,
    status: null,
    userId: null,
    userStatus: null,
    reviewed: null,
    order: [],
    orderAsc: []
  };

  orderList = orderList;
  limitList = [50, 100, 250, 500, 1000]
  limit = null

  // Export CSV Modal Options
  exportCsvModal: {
    visible: boolean,
    loading: boolean,
    type: string,
    articles: Array<string>,
    fields: Array<string>,
    questions: Array<number>,
    users: Array<UserModel>
  } = {
    visible: false,
    loading: false,
    type: 'xlsx',
    articles: [],
    fields: [],
    questions: [],
    users: [],
  }

  keepViewState = false
  formQueryValid = true

  rules: any = [];

  get reviewedByList() : Array<any> {
    const reviewedByUserWithRoleList: Array<{value: string, text: string}> = [];
    const reviewedByUserList: Array<{value: string, text: string}> = [];

    this.getProject()?.data.usernode.forEach((userNode: ProjectUserModel) => {
      const mappingTypes: any = {
        'leader': 'leader',
        'arbitrator': 'arbitrator',
        'researcher': 'primary researcher',
        'secondary-researcher': 'secondary researcher',
      };
      const userType = userNode.data.type;
      const userTypeText = mappingTypes[userType];
      const userFullName = userNode.data.userentity.getFullName();
      const userId = userNode.data.userentity.data.id;

      // by user node (ProjectUser)
      const reviewedByUserNodeText = 'reviewed by ' + userTypeText + ' ' + userFullName;
      const reviewedByUserNodeValue = 'reviewed by userType ' + userType + ' userId ' + userId;
      reviewedByUserWithRoleList.push({ value: reviewedByUserNodeValue, text: reviewedByUserNodeText});
      reviewedByUserWithRoleList.push({ value: 'not ' + reviewedByUserNodeValue, text: 'not ' + reviewedByUserNodeText});

      // by user
      const reviewedByUserText = 'reviewed by ' + userFullName;
      const reviewedByUserValue = 'reviewed by user userId ' + userId;
      reviewedByUserList.push({value: reviewedByUserValue, text: reviewedByUserText});
      reviewedByUserList.push({value: 'not ' + reviewedByUserValue, text: 'not ' + reviewedByUserText});
    });

    return [
      ...[{ divider: true }],
      ...reviewedByList,
      ...[{ divider: true }, { header: 'Users With Roles' }], { divider: true },
      ...reviewedByUserWithRoleList,
      ...[{ divider: true }, { header: 'Users List' }], { divider: true },
      ...reviewedByUserList,
    ];
  }

  canCreateRecord(): boolean {
    if (this.recordComponent && this.recordComponent.length > 0) {
      return this.recordComponent[0].canCreateRecord;
    }
    return false;
  }

  get canGoFirst(): boolean {
    return this.recordListPagePagination > 1;
  }

  get canGoPrevious(): boolean {
    return this.recordListPagePagination > 1;
  }

  get canGoNext(): boolean {
    return this.recordListPagePagination < this.numberOfPages;
  }

  get canGoLast(): boolean {
    return this.recordListPagePagination < this.numberOfPages;
  }

  get percentageType(): string {
    return this.percentageTypes[this.percentageTypeIdx];
  }

  get statusList() {
    if (
      (
        !this.getProject()?.hasRole(['leader', 'arbitrator']) // not a leader or arbitrator within the project
        && !Identity.hasRole(['dev', 'admin'])
      )
      || (this.mode && ['leader', 'arbitrator'].indexOf(this.mode && this.mode.value) === -1)// not a dev or admin globally
    ) {
      return statusList.filter(item => item.value !== 'conflict');
    }
    return statusList;
  }

  get availableKeywordList(): Array<any> {
    return this.keywordList.filter(keyword => !keyword.data.deleted);
  }

  get userId(): number {
    return Identity.getIdentity()?.user?.id
  }

  get projectStage(): 'screening' | 'indepth' | 'final' {
    return this.advanced.stage || this.getProject()?.data?.stage;
  }

  get modeList(): Array<any> {
    let items: Array<any> = [];
    if (Identity.hasRole(['dev', 'admin'])) {
      items = projectUserTypeList;
    } else {
      const filteredTypes = this.getProject()?.data?.usernode?.filter((usernode: any) => {
        return usernode.data.userId === Identity.getIdentity()?.user?.id
          || Identity.hasRole(['dev', 'admin']);
      }).map((projectUser: any) => projectUser.data.type) || [];

      for (let i = 0; i < projectUserTypeList.length; i++) {
        const userType: any = projectUserTypeList[i];
        if (filteredTypes.indexOf(userType.value) !== -1) {
          items.push(userType);
        }
      }
    }
    return items;
  }

  /**
   * Show User List
   * - If user has admin or dev global role
   * - If user is one of the selected projects leader
   */
  get showUserList() : boolean {
    return this.getProject()?.data?.usernode?.find((usernode: any) =>
        (!this.mode && usernode.data.type === 'leader' && usernode.data.userId === Identity.getIdentity()?.user?.id)
        || (this.mode && (['arbitrator'].indexOf(this.mode && this.mode.value) !== -1))
      );
  }

  get filterListWithField() {
    const isValid = (condition: any): boolean => {
      return (!!condition.field && condition.value) || (
        condition.operator === 'is empty'
        || condition.operator === 'is not empty'
      ) || condition.group.filter(hasCondition).length > 0;
    };
    const hasCondition = (condition: any): boolean => {
      if (Array.isArray(condition)) {
        for (let i = 0; i < condition.length; i++) {
          const subCondition = condition[i];
          if (isValid(subCondition)) {
            return true;
          }
        }
        return false;
      } else {
        return isValid(condition);
      }
    };
    return this.convertedFilters.filter(hasCondition);
  }

  get convertedFilters(): any {
    return Query.convertedFilters(this.filterList, queryMapper);
  }

  get hasFilters() : boolean {
    return !!this.filterListWithField.length;
  }

  get hasProject() : boolean {
    return !!this.projectId;
  }

  get hasRecords() : boolean {
    return !!this.recordList.length;
  }

  get hasKeywords() : boolean {
    return !!this.keywordList.length;
  }

  get hasCriteria() : boolean {
    return this.currentProject?.data.inclusionCriteria.length > 0
      || this.currentProject?.data.exclusionCriteria.length > 0;
  }

  get showModeSelection(): boolean {
    return !!this.modeList.length;
  }

  get showFilters(): boolean {
    return this.hasProject && this.mode;
  }

  get showQuery(): boolean {
    return this.hasProject && this.mode;
  }

  get showMetrics(): boolean {
    return this.hasProject && this.view === 'metrics';
  }

  get showKeywords(): boolean {
    return this.showRecords;
  }

  get showCriteria(): boolean {
    return this.showRecords;
  }

  get showOptions(): boolean {
    return this.showRecords;
  }

  get showProjectInformation(): boolean {
    return this.showRecords;
  }

  get showRecords(): boolean {
    return this.hasProject && this.view === 'records';
  }

  get numberOfPages() : number {
    return Math.ceil(this.recordList.length / this.itemsPerPage);
  }

  get itemsPerPage() : number {
    // return this.$vuetify.breakpoint.smAndDown ? 1 : 2;
    return 1;
  }

  get availableKeywordAllSelected(): boolean {
    for (let i = 0; i < this.availableKeywordList.length; i++) {
      const keyword = this.availableKeywordList[i];
      if (!keyword.selected) {
        return false;
      }
    }
    return true;
  }

  onProjectChange(): void {
    if (this.projectId) {
      this.loadProject(this.projectId)
        .then(() => this.saveViewState());
    }
  }

  onModeChange(): void {
    this.advanced.reviewed = null;
    this.autoAssignReviewedBy();
    this.saveViewState();
  }

  onApplyKeyword(model: KeywordModel | SynonymModel): Promise<any> {
    model.states.saving = true;
    const instance = model instanceof KeywordModel
      ? KeywordService.getInstance()
      : SynonymService.getInstance();

    const addCallback = () => {
      const wasNew = !(model.data.id);
      const wasSelected = model.selected;
      return instance.save(model)
        .then(response => model.assign(response.data.view.single))
        .catch(this.$root.$zemit.handleError)
        .finally(() => {
          model.selectedToggle(wasNew || wasSelected);
          model.states.saving = false;
          this.$forceUpdate();
        });
    }

    // If newly added keyword/synonym that already exists, ask the end-user if he wishes
    // to continue or not.
    if (
      !model.data.id
      && (
        (model instanceof KeywordModel && this.availableKeywordList.find(keyword => keyword.data.id && keyword.data.label.toLowerCase().trim() === model.data.label.toLowerCase().trim()))
        || (model instanceof SynonymModel && this.availableKeywordList.find(keyword => keyword.data.id && keyword.data.synonymlist.find((synonym: SynonymModel) => synonym.data.id && synonym.data.label.toLowerCase().trim() === model.data.label.toLowerCase().trim())))
      )
    ) {
      return new Promise((resolve) => this.$root.$shouldTakeAction.ask({
        title: 'Duplicate found',
        body: 'This item already exist in your list. Do you want to add it anyway?',
        actionText: 'Add anyway',
        color: 'warning',
        dark: true,
        onAccept: () => resolve(addCallback()),
      }));
    } else {
      return addCallback();
    }
  }

  onAddKeywordClick(): void {
    const model = new KeywordModel({
      projectId: this.projectId,
    });
    this.keywordList.push(model);
    this.$nextTick(() => {
      const ref = this.$refs['keyword_' + (this.availableKeywordList.length - 1).toString()];
      if (ref) {
        // @ts-ignore
        ref[0].editItem(model);
      }
    })
  }

  onAddSynonymClick(keyword: KeywordModel, keywordIdx: number): void {
    const synonym = new SynonymModel({
      id: null,
      label: null,
      keywordId: keyword.data.id,
      projectId: this.projectId,
    });
    keyword.data.synonymlist.push(synonym);

    const refKeyword = this.$refs['keyword_' + keywordIdx.toString()];
    if (refKeyword) {
      // @ts-ignore
      refKeyword[0].editKeywordSynonym(keyword, synonym);
    }
  }

  onSelectAllKeywordsClick(): void {
    const allSelected = this.availableKeywordAllSelected
    this.availableKeywordList.forEach((keyword: any) => {
      if ((allSelected && keyword.selected) || (!allSelected && !keyword.selected)) {
        keyword.selectedToggle();
      }
    })
  }

  onCreateRecordClick() {
    if (this.recordComponent && this.recordComponent.length > 0) {
      return this.recordComponent[0].onCreateRecordClick();
    }
  }

  onApplyPresetFilters(preset: Array<any>) {
    this.filterList = preset;
    this.saveViewState();
    this.queryLocked = true;
  }

  onPidChange(pid: number) {
    const index = this.recordList.findIndex(record => record.data.pid === pid);
    if (index !== -1) {
      this.recordListPage = index + 1;
    }
  }

  get selectedAllRecordFields () {
    return this.recordOptions.recordFields.length === this.recordFieldList.length;
  }

  get selectedSomeRecordFields () {
    return this.recordOptions.recordFields.length > 0 && !this.selectedAllRecordFields;
  }

  get recordFieldsIcon () {
    if (this.selectedAllRecordFields) return 'mdi-close-box'
    if (this.selectedSomeRecordFields) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  reloadRecord(record: RecordModel): Promise<any> {
    return RecordService.getInstance().get({
      id: record.data.id,
    })
      .then((response) => {
        record.assign(response.data.view.single);
        return record;
      })
  }

  onAfterSetStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Check next record only if project in pilot mode
      if (this.getProject()?.data.pilot) {
        this.recordListLoading = true;

        // Callback to check the next record index if assigned or not
        const nextRecordCallback = (index: number) => {
          return this.reloadRecord(this.recordList[index])
            .then((record) => {
              if (record.data.pilotUserId === null) {
                this.recordListLoading = false;
                resolve(record);
              } else if (this.recordList.length < index + 1) {
                nextRecordCallback(index + 1);
              } else { // End of the list
                resolve(null);
              }
            })
            .catch(reject);
        }

        nextRecordCallback(this.recordListPage);
      } else {
        resolve(null);
      }
    })
  }

  loadPresets(): Promise<any> {
    return ProjectFilterStateService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.projectId,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  savePresets(item: ProjectFilterStateModel) {
    return ProjectFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removePreset(item: ProjectFilterStateModel) {
    return ProjectFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  toggleRecordFields(): void {
    this.$nextTick(() => {
      if (this.selectedAllRecordFields) {
        this.recordOptions.recordFields = []
      } else {
        this.recordOptions.recordFields = this.recordFieldList.map((item: {text: string, value: string}) => {
          return item.value;
        });
      }
    });
  }

  onToggleSort(index: number): void {
    this.advanced.orderAsc[index] = !this.advanced.orderAsc[index];
    this.tick++; // Hack so #selection slot updates
    this.$forceUpdate();
  }

  onRecordListPagePaginationChange(page: number): void {
    clearTimeout(recordListPagePaginationChangeTimeout);
    recordListPagePaginationChangeTimeout = setTimeout(() => {
      this.recordListPage = page;
    }, 500);
  }

  setStatus(status: string) {
    this.recordComponent[0].setStatus(status);
  }

  // Go to first page
  firstPage() {
    this.recordListPage = 1;
  }

  // Go to last page
  lastPage() {
    this.recordListPage = this.numberOfPages;
  }

  // Go to next page
  nextPage() {
    if (this.recordListPage + 1 <= this.numberOfPages) {
      this.recordListPage += 1;
    } else {
      const record = this.recordComponent[0] as RecordComponent;
      record.loadRecordStatus();
    }
  }

  // Set page
  setPage(page: number) {
    this.recordListPage = page;
  }

  // Go to previous page
  previousPage() {
    if (this.recordListPage - 1 >= 1) {
      this.recordListPage -= 1;
    }
  }

  showWarningIfLast() {
    if (this.recordListPage === this.recordList.length) {
      this.$root.$globalSnack.info({
        message: 'You completed the list of record.'
      });
    }
  }

  /**
   * Open panel number
   * - Remove duplicates to avoid multiple clicks to close it back
   * @param panel
   */
  openPanels(panel: number | Array<number> = []) {
    if (!Array.isArray(panel)) {
      panel = [panel];
    }
    this.panelList = [...new Set([...this.panelList, ...panel])];
  }

  /**
   * Close panel number
   * - Remove duplicates to avoid multiple clicks to close it back
   * @param panel
   */
  closePanels(panel: number | Array<number> = []) {
    this.panelList = [...new Set(this.panelList.filter(item =>
      Array.isArray(panel) ? panel.indexOf(item) === -1 : item === panel
    ))];
  }

  /**
   * Search feature
   * - Build the filters and launch query to fetch records
   */
  search() {
    this.view = 'records';
    this.clearRecordList();
    this.clearMetrics();
    this.recordListLoading = true;

    this.openPanels([
      this.expansionPanel.records,
    ]);

    if (this.hasKeywords) {
      this.openPanels([
        this.expansionPanel.keywords,
      ]);
    }

    // Filters, order, advanced
    const filters = this.getFilters();
    const order = this.getOrder();
    const limit = this.getLimit();
    const advanced = this.getAdvanced();

    // Collapse keywords panel
    const keywordPanelIdx = this.panelList.indexOf(this.expansionPanel.keywords);
    if (keywordPanelIdx !== -1) {
      this.panelList.splice(keywordPanelIdx, 1);
    }

    // Fix first filter logic (must be AND)
    if (filters.length > 1 && filters[1].length > 0) {
      filters[1][0].logic = 'and';
    }

    // Query
    RecordService.getInstance().getAll({filters, order, limit, advanced})
      .then(response => this.recordList = response.data.view.list)
      .finally(() => this.recordListLoading = false);
  }

  /**
   * Show Metrics based on the filters
   */
  searchMetrics(global = true, userList: Array<UserModel> = this.metricFilters.users) {
    this.view = 'metrics';
    this.clearRecordList();
    this.closePanels([
      this.expansionPanel.keywords,
      this.expansionPanel.options,
      this.expansionPanel.records,
    ]);
    this.openPanels([
      this.expansionPanel.metrics,
    ]);

    // Filters, order, advanced
    const filters = this.getFilters();
    const limit = this.getLimit();
    const advanced = this.getAdvanced();

    // Global Query
    if (global) {
      this.metrics = [];
      this.metricsLoading = true;
      RecordService.getInstance().getMetrics({filters, limit, advanced})
        .then(response => this.metrics = response.data.view.metrics)
        .finally(() => this.metricsLoading = false);
    }

    // Selected Users Query
    if (userList) {
      this.usersMetrics = [];

      userList.forEach((user: UserModel, index: number) => {
        const userId = user.data.id;
        const advanced = {...this.getAdvanced(), userId};

        this.usersMetrics.push({
          title: user.getLabel(),
          loading: true,
          metrics: {},
        });
        RecordService.getInstance().getMetrics({filters, limit, advanced})
          .then(response => this.usersMetrics[index].metrics = response.data.view.metrics)
          .finally(() => this.usersMetrics[index].loading = false);
      });
    }
  }

  /**
   * Export CSV based on the filters
   */
  exportCsv() {
    const contentType = this.exportCsvModal.type;
    const filters = this.getFilters();
    const order = this.getOrder();
    const limit = '100000';
    const advanced = {
      ...this.getAdvanced(),
      type: this.exportCsvModal.type,
      articles: this.exportCsvModal.articles,
      fields: this.exportCsvModal.fields,
      questions: this.exportCsvModal.questions,
      users: [] as Array<number>,
      ai: false,
    }

    for (const user of this.exportCsvModal.users) {
      if (user.data.id !== 'ai') {
        advanced.users.push(user.data.id);
      } else {
        advanced.ai = true;
      }
    }

    // Query
    this.exportCsvModal.loading = true;
    RecordService.getInstance().export({filters, order, limit, advanced, contentType}, {responseType: 'blob'})
      .then((response: any) => {
        const fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename
          || this.currentProject?.data.label + ' - Records - ' + moment().format('YYYY-MM-DD') + '.' + this.exportCsvModal.type;
        saveAs(response.data, fileName);
        this.exportComponent.close();
      })
      .finally(() => this.exportCsvModal.loading = false);
  }

  onSaveStatusSuccess() {
    this.showWarningIfLast();
  }

  /**
   * Load current overall project progress
   */
  loadProjectProgress() {
    if (this.projectProgressComponent) {
      const promise = this.projectProgressComponent.loadProgress();
      if (promise) {
        promise.then(this.onProjectProgressChange);
      }
    }
  }

  getLimit(): number {
    return parseInt((this.limit || '0').toString()) || 1000000;
  }

  /**
   * Get & prepare filter query for the backend
   */
  getFilters(): any[] {
    return Query.getValidFilters(this.convertedFilters, this.projectId, queryMapper);
  }

  /**
   * Get & prepare order query for the backend
   */
  getOrder(): string[] {
    return this.advanced.order.map((order: string, index: number) => {
      return (this.advanced.order[index] || 'id') + ' ' + (this.advanced.orderAsc[index] ? 'asc' : 'desc');
    })
  }

  /**
   * Get & prepare advanced query for the backend
   */
  getAdvanced(): object {
    return this.advanced;
  }

  /**
   * Get the selected project from the list
   */
  getProject(): ProjectModel | null {
    return this.currentProject;
  }

  /**
   * Get the official status stage label
   */
  getOfficialStatusStageLabel(stage = this.advanced.stage) : string {
    return 'Official Status' + (stage? ' (' + this.stageList.find(item => item.value === stage)?.text || stage + ')' : '');
  }

  /**
   * Get the reviewed by stage label
   */
  getReviewedByStageLabel(stage = this.advanced.stage) : string {
    return 'Reviewed by' + (stage? ' (' + this.stageList.find(item => item.value === stage)?.text || stage + ')' : '');
  }

  /**
   * Fetch project, keywords and synonyms
   */
  @Watch('projectId')
  onProjectIdChange(id: string | number | null, previous: string | number | null) {
    this.clearRecordList();
    this.clearMetrics();
    this.clearKeywords();
    this.clearFilters();
    this.clearExport();

    if (previous) {
      this.clearMode();
    }

    if (this.presetManager && id) {
      this.presetManager.load();
    }

    // Set view back to default
    if (previous !== 0) {
      this.view = 'default';
    }

    // Project selected
    if (id) {
      this.setDefaultMode();

      this.openPanels([
        this.expansionPanel.filters,
        this.expansionPanel.query,
      ]);

      // Load related keywords
      this.keywordLoading = true;
      KeywordService.getInstance().getAll({filters: [{field: 'projectId', value: id, operator: 'equals'}]})
        .then(response => {
          this.keywordList = response.data.view.list;
          this.onSelectAllKeywordsClick();
        })
        .finally(() => this.keywordLoading = false);

      // Set default query
      this.autoAssignReviewedBy();
    }

    // No project selected
    else {
      // Close everything except the project selection
      this.closePanels([
        this.expansionPanel.filters,
        this.expansionPanel.query,
        this.expansionPanel.metrics,
        this.expansionPanel.keywords,
        this.expansionPanel.criteria,
        this.expansionPanel.options,
        this.expansionPanel.records,
      ]);
    }
  }

  onProjectProgressChange(response: any) {
    if (
      response.progress === 100
      || response.percentage.pass.percentage === 100
      || response.percentage.pending.percentage === 100
      || response.percentage.halt.percentage === 100
    ) {
      this.$root.$globalConfetti.fireworks();
    }
  }

  @Watch('recordListPage', {
    immediate: true
  })
  onRecordListPageChanged(page: number) {
    if (this.recordList[page - 1]) {
      this.reloadRecord(this.recordList[page - 1])
        .then(() => {
          this.recordListPagePagination = page;
        })
    }
  }

  @Watch('keepViewState')
  onKeepViewStateChanged(state: boolean) {
    localStorage.setItem('review_keep_view_state', JSON.stringify(state));
  }

  @Watch('view')
  @Watch('recordListPagePagination')
  @Watch('panelList', { deep: true })
  @Watch('filterList', { deep: true })
  @Watch('advanced', { deep: true })
  onStateChange() {
    this.saveViewState();
  }

  onPageInput(value: string): void {
    this.onRecordListPagePaginationChange(parseInt(value));
  }

  onExpansionPanelHeaderClick(panel: string): void {
    if (this.$refs[panel + '_panel']) {
      // @ts-ignore
      this.$refs[panel + '_panel'].expansionPanel.toggle();
    }
  }

  loadTags(): Promise<Array<TagModel>> {
    return SharedQuery.getProjectTagsByCategoryId(this.projectId)
      .then(tags => this.tagList = tags);
  }

  autoAssignReviewedBy(): void {
    if (this.mode) {
      if (this.mode.value === 'secondary-researcher') {
        this.advanced.reviewed = 'not reviewed by secondary researcher';
      } else if (this.mode.value === 'researcher') {
        this.advanced.reviewed = 'not reviewed by primary researcher';
      }

      // Uncomment if you wish to reload the resultset on mode change
      // if (this.showRecords) {
      //   this.search();
      // }
      return;
    }

    const project = this.getProject();
    if (project) {
      this.advanced.stage = project.data.stage;
      if (project.hasRole('secondary-researcher')) {
        this.advanced.reviewed = 'not reviewed by secondary researcher';
      }
      if (project.hasRole('researcher')) {
        this.advanced.reviewed = 'not reviewed by primary researcher';
      }
    }
  }

  setDefaultMode() {
    if (this.modeList.length === 1) {
      this.mode = this.modeList[0];
    }
  }

  /**
   * Clear Metrics & close panel
   */
  clearMetrics() {
    this.metrics = {};
    this.metricFilters = {
      users: [],
    }
    this.closePanels([
      this.expansionPanel.metrics
    ]);
  }

  /**
   * Clear Record list & close panel
   */
  clearRecordList() {
    this.recordList = [];
    this.recordListPage = 1;
    this.recordListPagePagination = 1;
    this.closePanels([
      this.expansionPanel.records
    ]);
  }

  /**
   * Clear Keywords & close panel
   */
  clearKeywords() {
    this.keywordList = [];
    this.closePanels([
      this.expansionPanel.keywords
    ]);
  }

  /**
   * Clear Filters & close panel
   */
  clearFilters() {
    this.filterList = JSON.parse(JSON.stringify(this.defaultPresetItem));
    this.closePanels([
      this.expansionPanel.filters
    ]);
  }

  /**
   * Clear export modal
   */
  clearExport() {
    Object.assign(this.exportCsvModal, {
      articles: [],
      fields: [],
      questions: [],
      users: [],
    });
  }

  clearMode() {
    this.mode = null;
  }

  loadProject(id: number): Promise<ProjectModel> {
    return ProjectService.getInstance().get({ id })
      .then(response => this.currentProject = response.data.view.single)
      .then(this.loadTags)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  timeoutCallback(fn: () => void, timeout = 0): number {
    return setTimeout(fn, timeout);
  }

  /**
   * Clear status & reviewedBy when stage is cleared
   */
  onStageClear() {
    this.advanced.stage = null;
    this.advanced.status = null;
    this.advanced.reviewed = null;
    this.advanced.userId = null;
    this.advanced.userStatus = null;
  }

  saveViewState(): void {
    if (this.keepViewState) {
      localStorage.setItem('review_view_state', JSON.stringify({
        limit: this.limit,
        advanced: this.advanced,
        filterList: this.filterList,
        projectId: this.projectId,
        mode: this.mode,
        view: this.view,
        recordOptions: this.recordOptions,
        panelList: this.panelList,
      }));
    }
  }

  autoSelectProject(projectId: number): Promise<number> {
    return new Promise((resolve) => {
      if (projectId) {
        this.projectId = projectId;
        this.loadProject(projectId).then(() => {
          if (this.showQuery && this.formQueryValid) {
            setTimeout(() => {
              if (['default', 'records'].includes(this.view)) {
                this.search();
              } else if (this.view === 'metrics') {
                this.searchMetrics();
              }
            })
          }
          return projectId;
        }).then(resolve);
      } else {
        resolve(projectId);
      }
    })
  }

  loadViewState(): void {
    const state = JSON.parse(localStorage.getItem('review_view_state') || '{}');

    Object.assign(this.advanced, state.advanced || {});
    Object.assign(this.filterList, state.filterList || {});
    Object.assign(this.recordOptions, state.recordOptions || {});

    // Check if values array to support recent
    // change in data structure
    if (!Array.isArray(this.advanced.order)) {
      this.advanced.order = [this.advanced.order];
    }
    if (!Array.isArray(this.advanced.orderAsc)) {
      this.advanced.orderAsc = [this.advanced.orderAsc];
    }

    this.mode = state.mode ? state.mode : this.mode;
    this.view = state.view ? state.view : this.view;
    this.limit = state.limit ? state.limit : this.limit;
    this.panelList = state.panelList ? state.panelList : this.panelList;
    this.autoSelectProject(state.projectId ? state.projectId : this.projectId)
      .finally(() => this.ready = true);
  }

  onProjectLoad(projects: Array<ProjectModel>) {
    if (!this.keepViewState && projects.length > 0) {
      this.ready = true
      // this.mode = this.modeList[0];
      // this.autoSelectProject(projects[0].data.id)
      //   .finally(() => this.ready = true);
    }
  }

  destroyed() {
    this.$root.$emit('toggleFullscreen', false);
  }

  created() {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required');
    this.rules.minMaxPage = (value: number) => Rules.isBetween(value, 1, this.numberOfPages) || this.$t('rules.minMaxPage', {
      min: 1,
      max: this.numberOfPages,
    });

    this.keepViewState = JSON.parse(localStorage.getItem('review_keep_view_state') || 'false') || false;
    if (this.keepViewState) {
      this.loadViewState();
    }
  }
}
</script>

<style lang="scss" scoped>
.ReviewView .v-expansion-panel-header {
  cursor: default;
}
.header-unclickable .v-expansion-panel-header {
  cursor: default;
}
#fullscreen-wrapper {
  position: static !important;
}
#fullscreen-wrapper.fullscreen {
  padding: 0 !important;
}
#fullscreen-wrapper.fullscreen > .v-expansion-panels > *:not(.fullscreen-keep) {
  display: none;
}
#fullscreen-wrapper.fullscreen > .v-expansion-panels > .fullscreen-keep {
  margin-top: 0;
}
</style>
