<template>
  <div v-if="showQuickStatus">

    <StatusReasonModal
      ref="statusReasonModalRef"
      v-model="statusReasonModal.visible"
      :record="record"
      status="halt"
      stage="indepth"
      @reason="onStatusReasonConfirm"
    />

    <template v-for="(recordStatus, recordStatusIdx) in recordStatusList">
      <v-tooltip
        :key="recordStatusIdx"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :plain="record.data[stage + 'Status'] !== recordStatus.status"
            :loading="recordStatus.loading"
            :disabled="!canApplyStatus"
            icon
            @click="saveStatus(recordStatus)"
          >
            <v-icon :color="recordStatus.color">mdi-circle</v-icon>
          </v-btn>
        </template>
        <span v-text="$t('status.' + recordStatus.status)"></span>
      </v-tooltip>

    </template>

  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, Emit, ModelSync, Ref} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import RecordService from '@/services/record.service';
import RecordModel from '@/models/record.model';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import StatusReasonModal from '@/views/Admin/Component/Record/StatusReasonModal.vue';
import {SnackError} from '@/modules/common/core/error';

const d = new Logger('views/Admin/Component/RecordComponent');

interface IStatus {
  status: string,
  color: string,
  loading: boolean,
}

@Component({
  components: {
    StatusReasonModal
  }
})
export default class RecordQuickStatusComponent extends Vue {
  @ModelSync('vModel', 'change', {type: RecordModel}) record!: RecordModel;
  @Prop({default: () => false}) disabled!: boolean;
  @Prop({default: () => 'screening'}) readonly stage!: 'screening' | 'indepth' | 'final' | string;
  @Prop() userType?: string;
  @Ref() readonly statusReasonModalRef!: StatusReasonModal
  loading = false;

  // Quick Record Status
  recordStatusList: Array<IStatus> = [
    {status: 'pass', color: 'success', loading: false},
    {status: 'pending', color: 'warning', loading: false},
    {status: 'halt', color: 'error', loading: false},
    // {status: 'skip', color: 'gray', loading: false},
  ];

  statusReasonModal: {
    visible: boolean,
    recordStatus: IStatus,
  } = {
    visible: false,
    recordStatus: this.recordStatusList[2],
  }

  get canApplyStatus(): boolean {
    return !this.loading && !this.disabled;
  }

  // Show Quick Status
  get showQuickStatus() {
    switch (this.stage) {
      case 'final':
        if (this.record.data.indepthStatus !== 'pass') {
          return false;
        }
      // eslint-disable-next-line no-fallthrough
      case 'indepth':
        if (this.record.data.screeningStatus !== 'pass') {
          return false;
        }
      // eslint-disable-next-line no-fallthrough
      case 'screening':
        return true;
    }
    return false;
  }

  onStatusReasonConfirm(reason: string | null) {
    this.saveStatus(this.statusReasonModal.recordStatus, reason);
  }

  /**
   * Save the record status
   * @param recordStatus
   * @param reason
   */
  @Emit()
  saveStatus(recordStatus: IStatus, reason?: ProjectStatusReasonModel | string | null) {
    const saveReasonStatus = recordStatus.status === 'halt' && this.stage !== 'screening';

    if (reason === undefined && saveReasonStatus) {
      recordStatus.loading = true;
      this.statusReasonModalRef.load()
        .then((response) => {
          if (!response.data.view.list) {
            throw new SnackError({
              title: 'The status has not been updated',
              message: 'Unable to retrieve status reason list'
            });
          }
          // Open Status Reason Modal
          Object.assign(this.statusReasonModal, {
            visible: true,
            recordStatus,
          })
        })
        .catch(reason => {
          this.saveStatusFailed();
          this.$root.$zemit.handleError(reason);
        })
        .finally(() => recordStatus.loading = false);
    }
    else {

      const callback = (data: any) => {
        return RecordService.getInstance().saveUserStatus(data, {
          params: {
            userType: this.userType,
          }
        })
          .then((response) => {
            if (!response.data.view.saved || !response.data.view.single) {
              throw new SnackError({
                title: 'The status has not been updated',
                message: 'An unexpected error has occurred'
              });
            }
            // Success
            this.record.assign(response.data.view.single);
            this.$root.$globalSnack.success({
              title: 'Record status has been successfully updated',
              message: 'The `' + this.stage + '` stage is now set to `' + recordStatus.status + '`'
            });
            this.saveStatusSuccess();
            this.statusReasonModalRef.close();
          })
          .catch(reason => {
            this.saveStatusFailed();
            this.$root.$zemit.handleError(reason)
          })
          .finally(() => {
            this.loading = false;
            recordStatus.loading = false;
          });
      }

      this.loading = true;
      recordStatus.loading = true;

      // Record data to save
      const data: any = {
        id: this.record.data.id
      };
      data[this.stage + 'Status'] = recordStatus.status;

      if (!saveReasonStatus) {
        data[this.stage + 'ProjectStatusReasonId'] = null;
        return callback(data);
      }
      if (reason instanceof ProjectStatusReasonModel) {
        data[this.stage + 'ProjectStatusReasonId'] = reason.data.id;
        return callback(data);
      }
      else {
        const model = new ProjectStatusReasonModel({
          projectId: this.record.data.projectId,
          label: reason,
          status: recordStatus.status,
          stage: this.stage,
        });

        return ProjectStatusReasonService.getInstance().save(model)
          .then(response => {
            // error
            if (!response.data.view.saved || !response.data.view.single) {
              throw new SnackError({
                title: 'The status reason has not been saved',
                message: 'An unexpected error has occurred'
              });
            }
            // success
            data[this.stage + 'ProjectStatusReasonId'] = response.data.view.single.data.id;
            callback(data);
          })
          .catch(reason => {
            this.$root.$zemit.handleError(reason);
            this.loading = false;
            recordStatus.loading = false;
          });
      }
    }
  }

  @Emit()
  saveStatusSuccess() {
  }

  @Emit()
  saveStatusFailed() {
  }
}
</script>
