<template>
  <ListBuilder
    v-model="value.data.surveygrouplist"
    :default-model="groupModel"
    :default-data="{
      surveyId: value.data.id,
      projectId: value.data.projectId,
    }"
    :sortable="sortable"
    :labels="_labels.groups"
    :show-removed="showRemoved"
    order-by="position"
    is-model
    restorable
    collapsable
  >
    <template v-if="showTemplates" #prepend>
      <v-card color="backgroundLight" class="mb-4 mx-n4" tile flat style="max-width: calc(100% + 32px)">
        <v-card-text>
          <div class="d-flex align-center justify-space-between px-4" style="gap: 1rem">
            <v-progress-linear
              v-if="loadingTemplates"
              indeterminate
              style="max-width: 50%"
            />
            <template v-else>
              <h3>Templates:</h3>
              <template v-if="templates.length === 0">
                <span>No template yet.</span>
                <v-btn
                  :to="{ name: 'DataExtractionPresetsForm', params: { id: 'new' } }"
                  x-small
                  outlined
                >
                  Create one
                </v-btn>
              </template>
              <div v-else class="d-flex align-center" style="gap: 2rem">
                <v-chip-group>
                  <v-chip
                    v-for="template in templates"
                    :key="template.data.id"
                    dark
                    label
                    @click="() => onApplyTemplate(template)"
                  >{{ template.data.label }}</v-chip>
                </v-chip-group>
              </div>
            </template>

            <v-spacer />

            <v-checkbox
              v-model="showRemoved"
              label="Show removed"
            />
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template #header="group">

      <!-- GROUP COLOR AND LABEL -->
      <div class="d-flex align-center" style="gap: 1rem">

        <!-- COLOR PICKER -->
        <ColorInput
          v-model="group.item.data.color"
          :readonly="readonly"
          :suggestions="[
            $vuetify.theme.themes.light.success,
            $vuetify.theme.themes.light.warning,
            $vuetify.theme.themes.light.error,
            $vuetify.theme.themes.light.skip,
          ]"
          outlined
          clearable
          hide-details="auto"
          placeholder="Color"
          skip-alpha
        />

        <v-row>

          <!-- LABEL -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="group.item.data.label"
              v-bind="groupAttrs"
              :readonly="readonly"
              :rules="[rules.required]"
              hide-details="auto"
              outlined
              clearable
            >
              <template #label>
                <span class="error--text">* </span>
                <span v-text="groupAttrs.label"></span>
              </template>
            </v-text-field>
          </v-col>

          <!-- SHOW FROM STAGE -->
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="group.item.data.stage"
              :readonly="readonly"
              :items="stageList"
              label="Show from stage"
              hide-details="auto"
              outlined
              clearable
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template #item="group">

      <!-- GROUP CONTAINER -->
      <v-alert
        outlined
        border="left"
        :class="['ma-0 mt-4 w-100', { 'mb-12': group.index < value.data.surveygrouplist.length - 1 }]"
        :color="group.item.data.color"
      >
        <!-- QUESTIONS -->
        <v-container v-show="showGroup" fluid class="pa-0">
          <v-card flat>
            <v-card-title>
              <span v-text="$tc('survey.question.title', group.item.data.surveyquestionlist.length, {
                total: group.item.data.surveyquestionlist.filter(item => showRemoved || item.data.deleted !== 1).length,
              })"></span>
            </v-card-title>
            <v-card-text>
              <ListBuilder
                v-model="group.item.data.surveyquestionlist"
                :default-model="questionModel"
                :default-data="{
                  projectId: value.data.projectId,
                  surveyId: value.data.id,
                  surveyGroupId: group.item.data.id,
                }"
                :sortable="sortable"
                :labels="_labels.questions"
                :show-removed="showRemoved"
                order-by="position"
                restorable
              >
                <template #item="question">
                  <v-alert
                    outlined
                    border="left"
                    class="ma-0 w-100"
                  >
                    <v-alert
                      v-if="question.item.data.deleted"
                      type="error"
                      dense
                      text
                    >
                      This question has been deleted.
                    </v-alert>

                    <v-alert
                      v-if="hasAnswer(question.item)"
                      type="warning"
                      dense
                      text
                    >
                      This question already has answers.
                    </v-alert>

                    <!-- LABEL -->
                    <v-text-field
                      v-model="question.item.data.label"
                      v-bind="questionAttrs"
                      :rules="[rules.required]"
                      :readonly="readonly"
                      :disabled="hasAnswer(question.item)"
                      :error-message="$t('surveyBuilder.validation.questionRequired')"
                      hide-details="auto"
                      clearable
                      outlined
                    >
                      <template #label>
                        <span class="error--text">* </span>
                        <span v-text="questionAttrs.label"></span>
                      </template>
                    </v-text-field>

                    <!-- TYPE -->
                    <v-select
                      v-model="question.item.data.type"
                      :items="types"
                      :rules="[rules.required]"
                      :readonly="readonly"
                      :disabled="hasAnswer(question.item)"
                      outlined
                      class="mt-4"
                      hide-details="auto"
                    >
                      <template #item="{ item }">
                        <v-icon v-text="item.icon" left></v-icon>
                        <div>
                          <h5 v-text="$t('survey.question.types.' + item.value)"></h5>
                          <div v-text="$t('survey.question.typesDesc.' + item.value)" class="caption"></div>
                        </div>
                      </template>
                      <template #selection="{ item }">
                        <v-icon v-text="item.icon" left></v-icon>
                        <h5 v-text="$t('survey.question.types.' + item.value)"></h5>
                      </template>
                      <template #label>
                        <span class="error--text">* </span>
                        <span v-text="$t('surveyBuilder.question.type')"></span>
                      </template>
                    </v-select>

                    <!-- OPTIONS -->
                    <v-card outlined class="mt-4">
                      <v-expansion-panels flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <div>
                              <v-icon left>mdi-clipboard-list-outline</v-icon>
                              <span v-text="$tc('survey.option.title', question.item.data.surveychoicelist.length, {
                                total: question.item.data.surveychoicelist.length,
                              })"></span>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ListBuilder
                              v-model="question.item.data.surveychoicelist"
                              :default-model="choiceModel"
                              :default-data="{
                                projectId: value.data.projectId,
                                surveyId: value.data.id,
                                surveyGroupId: group.item.data.id,
                                surveyQuestionId: question.item.data.id,
                              }"
                              :sortable="sortable"
                              :labels="_labels.options"
                              :disabled="hasAnswer(question.item)"
                              :show-removed="showRemoved"
                              order-by="position"
                              restorable
                            >
                              <template #item="choice">

                                <!-- LABEL -->
                                <v-text-field
                                  v-model="choice.item.data.content"
                                  v-bind="optionAttrs"
                                  :readonly="readonly"
                                  :rules="[rules.required]"
                                  :disabled="hasAnswer(question.item)"
                                  clearable
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </template>
                            </ListBuilder>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>

                    <!-- ADVANCED -->
                    <v-card outlined class="mt-4">
                      <v-expansion-panels flat>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <div>
                              <v-icon left>mdi-card-bulleted-settings-outline</v-icon>
                              <span>Advanced</span>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>

                            <!-- DESCRIPTION -->
                            <v-text-field
                              v-model="question.item.data.description"
                              :label="$t('surveyBuilder.question.hint')"
                              :readonly="readonly"
                              clearable
                              outlined
                              hide-details="auto"
                            />

                            <!-- SETTINGS -->
                            <v-row class="mt-4">

                              <!-- REQUIRED? -->
                              <v-col v-if="canBeRequired(question.item)" cols="12" md="3">
                                <v-checkbox
                                  v-model="question.item.data.required"
                                  :label="$t('surveyBuilder.rules.required')"
                                  class="ma-0 pa-0"
                                  hide-details="auto"
                                />
                              </v-col>

                              <!-- POSITIVE ONLY? -->
                              <v-col v-if="canBePositiveOnly(question.item)" cols="12" md="3">
                                <v-checkbox
                                  v-model="question.item.data.positiveOnly"
                                  :disabled="question.item.data.negativeOnly"
                                  :label="$t('surveyBuilder.rules.positiveOnly')"
                                  type="number"
                                  class="ma-0 pa-0"
                                  hide-details="auto"
                                  @change="revalidate([
                                    $refs['min_' + question.index],
                                    $refs['max_' + question.index]
                                  ])"
                                />
                              </v-col>

                              <!-- NEGATIVE ONLY? -->
                              <v-col v-if="canBeNegativeOnly(question.item)" cols="12" md="3">
                                <v-checkbox
                                  v-model="question.item.data.negativeOnly"
                                  :label="$t('surveyBuilder.rules.negativeOnly')"
                                  :disabled="question.item.data.positiveOnly"
                                  type="number"
                                  class="ma-0 pa-0"
                                  hide-details="auto"
                                  @change="revalidate([
                                    $refs['min_' + question.index],
                                    $refs['max_' + question.index]
                                  ])"
                                />
                              </v-col>

                              <!-- PAST ONLY? -->
                              <v-col v-if="canBePastOnly(question.item)" cols="12" md="3">
                                <v-checkbox
                                  v-model="question.item.data.pastOnly"
                                  :label="$t('surveyBuilder.rules.pastOnly')"
                                  type="number"
                                  class="ma-0 pa-0"
                                  hide-details="auto"
                                  @change="revalidate([
                                    $refs['date_from_' + question.index],
                                    $refs['date_to_' + question.index]
                                  ])"
                                />
                              </v-col>

                              <!-- MULTIPLE? -->
                              <v-col v-if="canBeMultiple(question.item)" cols="12" md="3">
                                <v-checkbox
                                  v-model="question.item.data.multiple"
                                  :label="$t('surveyBuilder.rules.multiple')"
                                  :disabled="hasAnswer(question.item)"
                                  class="ma-0 pa-0"
                                  hide-details="auto"
                                />
                              </v-col>
                            </v-row>

                            <!-- COUNTS -->
                            <v-row v-if="canHaveDecimal(question.item) || question.item.data.type === 'rating'" class="mt-4">

                              <!-- DECIMAL? -->
                              <v-col v-if="canHaveDecimal(question.item)" cols="12" md="3">
                                <v-text-field
                                  v-model.number="question.item.data.decimal_count"
                                  :label="$t('surveyBuilder.rules.decimalCount')"
                                  type="number"
                                  prepend-icon="mdi-decimal"
                                  hide-details="auto"
                                  outlined
                                />
                              </v-col>

                              <!-- RATING COUNT? -->
                              <v-col v-if="question.item.data.type === 'rating'" cols="12" md="3">
                                <v-text-field
                                  v-model.number="question.item.data.rating_count"
                                  :label="$t('surveyBuilder.rules.ratingCount')"
                                  :min="0"
                                  type="number"
                                  prepend-icon="mdi-star-outline"
                                  hide-details="auto"
                                  outlined
                                />
                              </v-col>
                            </v-row>

                            <!-- MIN/MAX -->
                            <v-row v-if="canHaveMinMax(question.item) || canHaveFromToDate(question.item) || canHaveFromToTime(question.item)" class="mt-4">

                              <!-- MIN/MAX LENGTH? -->
                              <v-col v-if="canHaveMinMax(question.item)" cols="12" md="6" class="d-flex align-center" style="gap: 1rem">
                                <v-text-field
                                  v-model.number="question.item.data[getNumberType('min', question.item.data.type)]"
                                  :ref="'min_' + question.index"
                                  :label="$t('surveyBuilder.rules.minLength')"
                                  :rules="[value => rules.minMaxLength(value, question.item.data[getNumberType('max', question.item.data.type)], question.item)]"
                                  type="number"
                                  prepend-icon="mdi-counter"
                                  clearable
                                  outlined
                                  hide-details="auto"
                                />
                                <v-text-field
                                  v-model.number="question.item.data[getNumberType('max', question.item.data.type)]"
                                  :ref="'max_' + question.index"
                                  :label="$t('surveyBuilder.rules.maxLength')"
                                  :rules="[value => rules.minMaxLength(question.item.data[getNumberType('min', question.item.data.type)], value, question.item)]"
                                  :min="question.item.data.positiveOnly ? 0 : null"
                                  :max="question.item.data.negativeOnly ? 0 : null"
                                  type="number"
                                  prepend-icon="mdi-counter"
                                  clearable
                                  outlined
                                  hide-details="auto"
                                />
                              </v-col>

                              <!-- MIN/MAX DATE/TIME? -->
                              <v-col v-if="canHaveFromToDate(question.item) || canHaveFromToTime(question.item)" cols="12" md="6">
                                <div v-if="canHaveFromToDate(question.item)" class="d-flex align-center" style="gap: 1rem">

                                  <!-- FROM -->
                                  <v-dialog
                                    v-model="dialogDateFrom[question.index]"
                                    :ref="'dialogDateFrom_' + question.index"
                                    :return-value.sync="question.item.data.date_from"
                                    persistent
                                    eager
                                    width="290px"
                                    style="flex: 1"
                                    clearable
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="question.item.data.date_from"
                                        v-bind="attrs"
                                        v-on="on"
                                        :ref="'date_from_' + question.index"
                                        :label="$t('placeholder.fromDate')"
                                        :rules="[value => rules.minMaxDate(value, question.item.data.date_from, question.item)]"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        clearable
                                        outlined
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="question.item.data.date_from"
                                      :max="question.item.data.date_to"
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="dialogDateFrom[question.index] = false"
                                      >
                                        <span v-text="$t('btn.cancel')"></span>
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs['dialogDateFrom_' + question.index]?.save(question.item.data.date_from)"
                                      >
                                        <span v-text="$t('btn.apply')"></span>
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>

                                  <!-- TO -->
                                  <v-dialog
                                    v-model="dialogDateTo[question.index]"
                                    :ref="'dialogDateTo_' + question.index"
                                    :return-value.sync="question.item.data.date_to"
                                    persistent
                                    width="290px"
                                    style="flex: 1"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="question.item.data.date_to"
                                        v-bind="attrs"
                                        v-on="on"
                                        :ref="'date_to_' + question.index"
                                        :label="$t('placeholder.toDate')"
                                        :rules="[value => rules.minMaxDate(question.item.data.date_to, value, question.item)]"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        clearable
                                        outlined
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="question.item.data.date_to"
                                      :min="question.item.data.date_from"
                                      scrollable
                                      clearable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="dialogDateTo[question.index] = false"
                                      >
                                        <span v-text="$t('btn.cancel')"></span>
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs['dialogDateTo_' + question.index]?.save(question.item.data.date_to)"
                                      >
                                        <span v-text="$t('btn.apply')"></span>
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                                <div v-if="canHaveFromToTime(question.item)" class="d-flex align-center" style="gap: 1rem">
                                  <v-select
                                    v-model="question.item.data.min_time"
                                    :items="timeSlots"
                                    :label="$t('placeholder.fromTime')"
                                    style="flex: 1"
                                    prepend-icon="mdi-clock-outline"
                                    hide-details="auto"
                                    clearable
                                    outlined
                                  />
                                  <v-select
                                    v-model="question.item.data.max_time"
                                    :items="timeSlots"
                                    :label="$t('placeholder.toTime')"
                                    style="flex: 1"
                                    prepend-icon="mdi-clock-outline"
                                    hide-details="auto"
                                    clearable
                                    outlined
                                  />
                                </div>
                              </v-col>
                            </v-row>

                            <!-- QUESTIONS FOR THE AI -->
                            <v-alert
                              outlined
                              border="left"
                              class="ma-0 w-100 mt-6"
                            >
                              <v-card flat>
                                <v-card-title>
                                  <span v-text="$tc('survey.questionAi.title', question.item.data.surveyaiquestionlist.length, {
                                    total: question.item.data.surveyaiquestionlist.length,
                                  })"></span>
                                </v-card-title>
                                <v-card-text>
                                  <ListBuilder
                                    v-model="question.item.data.surveyaiquestionlist"
                                    :sortable="sortable"
                                    :labels="_labels.questionsAi"
                                    :default-model="questionAiModel"
                                    :default-data="{
                                      projectId: value.data.projectId,
                                      surveyId: value.data.id,
                                      surveyGroupId: group.item.data.id,
                                      surveyQuestionId: question.item.data.id,
                                      content: ''
                                    }"
                                    :show-removed="showRemoved"
                                    order-by="position"
                                    restorable
                                  >
                                    <template #item="questionAi">
                                      <v-text-field
                                        v-model="questionAi.item.data.content"
                                        :label="$t('surveyBuilder.question.ai')"
                                        :readonly="readonly"
                                        prepend-inner-icon="mdi-robot"
                                        clearable
                                        outlined
                                        hide-details="auto"
                                      />
                                    </template>
                                  </ListBuilder>
                                </v-card-text>
                              </v-card>
                            </v-alert>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </v-alert>
                </template>
              </ListBuilder>
            </v-card-text>
          </v-card>
        </v-container>
      </v-alert>
    </template>
  </ListBuilder>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import {ISurveyQuestion} from '@/components/Survey/Survey.vue';
import ListBuilder from '@/modules/common/components/ListBuilder.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import ColorInput from '@/modules/common/components/ColorInput.vue';
import SurveyGroupModel from '@/models/survey-group.model';
import SurveyQuestionModel from '@/models/survey-question.model';
import SurveyChoiceModel from '@/models/survey-choice.model';
import Rules from '@/modules/sdk/core/rules';
import SurveyAiQuestionModel from '@/models/survey-ai-question.model';
import VueI18n from 'vue-i18n';
import SurveyAnswerService from '@/services/survey-answer.service';
import SurveyAnswerModel from '@/models/survey-answer.model';
import SurveyModel from '@/models/survey.model';
import SurveyService from '@/services/survey.service';
import { stageList } from '@/enums/global';

@Component({
  components: {
    ListBuilder,
    ColorInput,
    PresetManager,
  }
})
export default class SurveyBuilder extends Vue {
  @Prop({
    type: SurveyModel,
    default: () => new SurveyModel(),
  }) value!: SurveyModel

  @Prop({
    type: Boolean,
    default: false,
  }) readonly?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) sortable?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) disableIfAnswered?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) showTemplates?: boolean

  @Prop({
    type: Object,
    default: () => null,
  }) labels?: any

  @Prop({
    type: Object,
    default: () => ({}),
  }) groupAttrs?: any

  @Prop({
    type: Object,
    default: () => ({}),
  }) questionAttrs?: any

  @Prop({
    type: Object,
    default: () => ({}),
  }) optionAttrs?: any

  showGroup = true
  showFromStage = true
  loadingAnswers = false
  showRemoved = false
  groupModel = SurveyGroupModel
  questionModel = SurveyQuestionModel
  questionAiModel = SurveyAiQuestionModel
  choiceModel = SurveyChoiceModel
  answers: Array<SurveyAnswerModel> = []
  dialogDateFrom: any = {}
  dialogDateTo: any = {}
  timeSlots: Array<string> = []
  stageList = stageList
  rules: any = {}
  types: Array<{
    header?: string | VueI18n.LocaleMessages;
    icon?: string,
    value?: string
  }> = []

  loadingTemplates = false
  templates: Array<SurveyModel> = []

  get _labels(): any {
    return Object.assign({}, {
      groups: this.labels?.groups || {},
      questions: this.labels?.questions || {},
      questionsAi: this.labels?.questionsAi || {},
      options: this.labels?.options || {},
    })
  }

  onApplyTemplate(template: SurveyModel) {
    this.$root.$shouldTakeAction.ask({
      title: 'Append template',
      body: 'This action will merge this template into your current data. Do you wish to proceed?',
      actionText: 'Append',
      color: 'warning',
      dark: true,
      onAccept: () => this.appendSurvey(template)
    })
  }

  appendSurvey(survey: SurveyModel) {
    const clone: SurveyModel = survey.clone();
    clone.cleanReferences();
    this.value.data.surveygrouplist = this.value.data.surveygrouplist.concat(clone.data.surveygrouplist);
    this.value.resetPosition();
    this.$emit('append-template', clone)
  }

  canBeRequired(item: ISurveyQuestion): boolean {
    return true;
  }

  canBeMultiple(item: ISurveyQuestion): boolean {
    return ['select', 'country', 'autocomplete'].includes(item.data.type);
  }

  canHaveMinMax(item: ISurveyQuestion): boolean {
    return ['number', 'year', 'number_range', 'decimal', 'integer', 'input', 'textarea', 'percentage'].includes(item.data.type);
  }

  canHaveFromToDate(item: ISurveyQuestion): boolean {
    return ['datetime', 'date', 'date_range', 'datetime_range'].includes(item.data.type);
  }

  canHaveFromToTime(item: ISurveyQuestion): boolean {
    return ['datetime', 'datetime_range', 'time_range', 'time'].includes(item.data.type);
  }

  canHaveDecimal(item: ISurveyQuestion): boolean {
    return ['decimal'].includes(item.data.type);
  }

  hasOptions(item: ISurveyQuestion): boolean {
    return ['select', 'checkbox', 'radio'].includes(item.data.type);
  }

  canBeNegativeOnly(item: ISurveyQuestion): boolean {
    return ['number', 'number_range', 'integer', 'percentage', 'decimal'].includes(item.data.type);
  }

  canBePositiveOnly(item: ISurveyQuestion): boolean {
    return ['number', 'number_range', 'integer', 'percentage', 'decimal'].includes(item.data.type);
  }

  canBePastOnly(item: ISurveyQuestion): boolean {
    return ['datetime', 'date', 'date_range', 'datetime_range'].includes(item.data.type);
  }

  getNumberType(direction = 'min', type: string) {
    const ccDirection = direction.substring(0, 1).toUpperCase() + direction.substring(1);
    if (['number', 'decimal'].includes(type)) {
      return type + ccDirection;
    }
    if (['integer', 'number_range', 'percentage', 'year'].includes(type)) {
      return 'number' + ccDirection;
    }
    return direction + 'Length';
  }

  revalidate(ref: any | Array<any>) {
    setTimeout(() => {
      if (!Array.isArray(ref)) {
        ref = [ref];
      }
      ref.forEach((r: any) => {
        const isValid = r.validate();
        r.error = !isValid;
      })
    })
  }

  hasAnswer(question: SurveyQuestionModel): boolean {
    return this.answers.findIndex(answer => answer.data.surveyQuestionId === question.data.id) !== -1;
  }

  loadTemplates(): Promise<Array<SurveyModel>> {
    this.loadingTemplates = true;
    return SurveyService.getInstance().getAll({
      filters: [{
        field: 'template',
        operator: 'equals',
        value: 1,
      }],
    })
      .then(response => this.templates = response.data.view.list)
      .catch(this.$root.$zemit.handleError)
      .finally(() => this.loadingTemplates = false);
  }

  checkAnswers(): Promise<Array<SurveyAnswerModel>> {
    this.loadingAnswers = true;
    return SurveyAnswerService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.value.data.projectId,
      }],
      group: 'surveyQuestionId',
    })
      .then(response => this.answers = response.data.view.list)
      .then(response => {
        this.value.setOriginalData();
        return response;
      })
      .catch(this.$root.$zemit.handleError)
      .finally(() => this.loadingAnswers = false);
  }

  created(): void {
    if (this.showTemplates) {
      this.loadTemplates();
    }

    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required');
    this.rules.minMaxLength = (min: number, max: number, item: ISurveyQuestion) => {
      if (
        (item.data.positiveOnly && (min < 0 || max < 0)) ||
        (item.data.negativeOnly && (min > 0 || max > 0))
      ) {
        return this.$t('surveyBuilder.validation.minMaxLength');
      }
      return ((min && max) ? min < max : true) || this.$t('surveyBuilder.validation.minMaxLength');
    }
    this.rules.minMaxDate = (min: string, max: string, item: ISurveyQuestion) => {
      const minTime = Date.parse(min);
      const maxTime = Date.parse(max);
      const today = new Date();
      today.setDate(today.getDate() - 1);
      const currentTime = today.getTime();
      if (item.data.pastOnly && (minTime > currentTime || maxTime > currentTime)) {
        return this.$t('surveyBuilder.validation.minMaxDate');
      }
      return true;
    }

    this.timeSlots = [];
    for (let i = 0; i < 48; i++) {
      const progress = i * 0.5;
      const isHalf = progress % 1 !== 0;
      const floor = Math.floor(progress);
      const hour = floor < 10 ? '0' + floor : floor;
      const minutes = isHalf ? '30' : '00';
      this.timeSlots.push(hour + ':' + minutes);
    }

    this.types = [
      // Numbers
      { header: this.$t('survey.question.typeGroups.numbers'), },
      { value: 'number', icon: 'mdi-numeric' },
      { value: 'number_range', icon: 'mdi-numeric-10-box-multiple-outline' },
      { value: 'integer', icon: 'mdi-numeric-1' },
      { value: 'percentage', icon: 'mdi-percent' },
      { value: 'decimal', icon: 'mdi-decimal' },

      // Texts
      { header: this.$t('survey.question.typeGroups.texts'), },
      { value: 'input', icon: 'mdi-cursor-text' },
      { value: 'textarea', icon: 'mdi-text' },

      // Options
      { header: this.$t('survey.question.typeGroups.options'), },
      { value: 'checkbox', icon: 'mdi-checkbox-blank-outline' },
      { value: 'radio', icon: 'mdi-radiobox-marked' },
      { value: 'select', icon: 'mdi-format-list-bulleted' },
      { value: 'country', icon: 'mdi-earth' },
      { value: 'autocomplete', icon: 'mdi-cloud-question' },

      // Dates & times
      { header: this.$t('survey.question.typeGroups.dates'), },
      { value: 'date', icon: 'mdi-calendar' },
      { value: 'date_range', icon: 'mdi-calendar-range' },
      { value: 'datetime', icon: 'mdi-timetable' },
      { value: 'datetime_range', icon: 'mdi-timetable' },
      { value: 'time', icon: 'mdi-av-timer' },
      { value: 'time_range', icon: 'mdi-av-timer' },
      { value: 'year', icon: 'mdi-calendar-month' },

      // Others
      { header: this.$t('survey.question.typeGroups.others'), },
      { value: 'rating', icon: 'mdi-star-outline' },
    ];

    if (this.disableIfAnswered && this.value.data.id) {
      this.checkAnswers();
    }
  }
}
</script>
