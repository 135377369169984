<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-fullscreen="fullscreenTeleportOptions"
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
        <v-icon v-else>mdi-fullscreen</v-icon>
      </v-btn>
    </template>
    <span>
      <span v-if="isFullscreen">Exit fullscreen</span>
      <span v-else>Enter fullscreen</span>
    </span>
  </v-tooltip>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('views/Admin/Component/Record/FullscreenComponent');

@Component({
  components: {}
})
export default class RecordComponent extends Vue {
  isFullscreen = false;

  fullscreenTeleportOptions = {
    pageOnly: true,
    target: '#fullscreen-wrapper',
    callback: (isFullscreen: boolean) => {
      this.$root.$emit('toggleFullscreen', isFullscreen);
    },
  };

  created() {
    this.$root.$on('toggleFullscreen', (isFullscreen: boolean) => {
      this.isFullscreen = isFullscreen;
    });
  }
}
</script>
