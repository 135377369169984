<template>
  <v-card>

    <AskQuestionToAIProjectModal
      v-model="askAiModal.visible"
      :record="askAiModal.record"
      :stage="askAiModal.stage"
      :disabled="!canChangeStatus"
      max-width="1200"
      scrollable
      @apply="onAiQuestionApply"
    />

    <ManageRecordUserStatusModal
      v-if="canManageRecordUserStatus"
      v-model="viewRecordUserStatusModal.visible"
      :reasons="viewRecordUserStatusModal.reasons"
      max-width="600"
      scrollable
      @apply="onApplyStatusReason"
    />

    <StatusReasonModal
      ref="statusReasonModalRef"
      v-model="statusReasonModal.visible"
      :record="record"
      :default-reason="statusReasonModal.reason"
      status="halt"
      stage="indepth"
      @reason="onStatusReasonConfirm"
      @cancel="onStatusReasonCancel"
    />

<!--    <v-card-title>-->
<!--      <div style="word-break: break-word">-->
<!--        <div v-if="_stageList.length === 0">New Record</div>-->
<!--        <div v-else>Record #{{ record.data.id }} - {{ record.data.uid }}</div>-->
<!--        <div class="caption text&#45;&#45;secondary" v-text="record.data.title"></div>-->
<!--      </div>-->
<!--    </v-card-title>-->

    <template v-if="showUserList">
      <v-card-text>
        <v-select
          v-model="selectedUser"
          :items="userList"
          :disabled="disabled"
          :item-value="getUserListItemValue"
          label="User"
          hide-details="auto"
          height="auto"
          outlined
          clearable
          @change="onUserChange"
        >
          <template #selection="{ item }">
            <div>
              <h5 class="mb-n1" v-text="item.getFullName()"></h5>
              <span class="caption">(<span v-text="getProjectUserTypeName(item.data.userType)"></span>)</span>
            </div>
          </template>
          <template #item="{ item }">
            <div>
              <h5 class="mb-n2" v-text="item.getLabel()"></h5>
              <span class="caption">(<span v-text="getProjectUserTypeName(item.data.userType)"></span>)</span>
            </div>
          </template>
        </v-select>
      </v-card-text>

      <v-divider v-if="_stageList.length > 0"></v-divider>
    </template>

    <v-list>
      <v-list-item v-if="listLoading">
        <v-list-item-content>
          <v-progress-linear
            indeterminate
          ></v-progress-linear>
        </v-list-item-content>
      </v-list-item>

      <template v-else>
        <v-list-item
          :key="stage.type"
          v-for="stage in _stageList"
        >
          <v-list-item-content>
            <v-select
              v-model="status[stage.type]"
              :loading="stage.loading"
              :items="statusList"
              :label="stage.label"
              :disabled="!canChangeStatus"
              :clearable="canChangeStatus"
              hide-details="auto"
              outlined
              required
              @change="saveStatus(stage)"
            >
              <template #item="{item}">
                <v-icon :color="item.color">
                  mdi-circle
                </v-icon>
                <span class="ml-2">
                {{ item.text }}
              </span>
              </template>
              <template #selection="{item}">
                <v-icon :color="item.color">
                  mdi-circle
                </v-icon>
                <span class="ml-2">
                {{ item.text }}
              </span>
              </template>
            </v-select>

            <!-- SHOW HALT STATUS -->
            <div v-if="statusReasons[stage.type]">
              <v-sheet class="mb-0 mt-n1 pa-2 d-flex align-center w-100 grey lighten-1 d-flex align-center justify-space-between" style="gap: 1rem; border-radius: 0 0 0.25rem 0.25rem" dark>
                <div>
                  <v-icon left small>mdi-alert-rhombus-outline</v-icon>
                  <span class="caption" v-text="statusReasons[stage.type]"></span>
                </div>
                <v-btn
                  v-if="canManageRecordUserStatus"
                  outlined
                  x-small
                  @click="() => onEditReasonOfExclusionClick(stage)"
                >
                  Edit
                </v-btn>
              </v-sheet>
            </div>

            <!-- QUESTION FOR AI -->
            <div class="mt-1">
              <v-btn
                text
                x-small
                @mousedown.prevent.stop="onAskQuestionAi()"
              >
                <v-icon x-small left>mdi-robot</v-icon>
                <span v-text="$t('surveyAnswer.askQuestionAi')"></span>
              </v-btn>
            </div>

            <!-- MANAGE REASONS -->
            <template v-if="reasonsLoaded && canManageRecordUserStatus">
              <div v-if="allReasonList[stage.type].items.length > 0">
                <v-btn
                  :color="allReasonList[stage.type].conflicting ? 'error': null"
                  text
                  x-small
                  @mousedown.prevent.stop="onViewAnswersClick(allReasonList[stage.type].items)"
                >
                  <template v-if="allReasonList[stage.type].conflicting">
                    <v-icon x-small left>mdi-alert</v-icon>
                    <span v-text="$t('surveyAnswer.conflictingAnswers', {
                      total: allReasonList[stage.type].items.length,
                    })"></span>
                  </template>
                  <template v-else>
                    <v-icon x-small left>mdi-eye</v-icon>
                    <span v-text="$tc('surveyAnswer.viewAnswers', allReasonList[stage.type].items.length, {
                      total: allReasonList[stage.type].items.length,
                    })"></span>
                  </template>
                </v-btn>

                <div v-if="canChangeStatus && allReasonList[stage.type].suggestion && status[stage.type] !== allReasonList[stage.type].suggestion?.value" class="mt-1 ml-2 caption d-flex align-center" style="gap: 0.5rem">
                  <span>Suggested status:</span>
                  <v-btn
                    outlined
                    x-small
                    :color="allReasonList[stage.type].suggestion?.color"
                    @click="() => saveStatus(
                      allReasonList[stage.type].suggestion,
                      record,
                      allReasonList[stage.type].suggestion?.value,
                      userType,
                    )"
                  >
                    <span v-text="allReasonList[stage.type].suggestion?.text"></span>
                  </v-btn>
                </div>

                <div v-if="allReasonList[stage.type].items.length === 1" class="mt-1 ml-2 caption warning--text">
                  <v-icon color="warning" x-small left>mdi-alert</v-icon>
                  <span>Partially answered</span>
                </div>
              </div>
              <div v-else class="mt-1 ml-2 caption warning--text">
                <v-icon color="warning" x-small left>mdi-alert</v-icon>
                <span>Not answered yet</span>
              </div>
            </template>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <v-sheet v-if="aiHistory.length > 0" class="backgroundVeryLight">
      <v-divider />
      <v-subheader class="font-weight-bold pt-4" style="height: auto">
        Last AI response
      </v-subheader>
      <v-list-item>

        <v-list-item-content
          v-for="entry in aiHistory"
          :key="entry.data.id"
        >
          <div>
            <v-chip :color="getStatus(entry.data.status)?.color" label small>
              <span v-text="getStatus(entry.data.status)?.text"></span>
            </v-chip>
          </div>

          <div class="my-3">
            <strong>Confidence</strong>: {{ entry.data.confidence }}
            <div class="d-flex align-center">
              <strong>Rating</strong>:
              <v-rating
                v-model.number="entry.data.rating"
                color="warning"
                background-color="warning"
                @input="onRatingChange(entry)"
              ></v-rating>
            </div>
          </div>
          <div
            v-if="!Array.isArray(splitComment(entry.data.comment))"
            class="caption"
            v-html="splitComment(entry.data.comment)"
          ></div>
          <v-expansion-panels
            v-else
            v-model="splitCommentPanels"
            multiple
          >
            <v-expansion-panel
              v-for="(section, sectionIdx) in splitComment(entry.data.comment)"
              :key="sectionIdx"
            >
              <v-expansion-panel-header>{{ section.title }}</v-expansion-panel-header>
              <v-expansion-panel-content>{{ section.body }}</v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>

    <v-sheet class="backgroundVeryLight">
      <v-divider />
      <v-alert v-if="userList.length === 0" color="transparent" icon="mdi-account-alert" class="mb-0 caption" tile>
        <span v-text="$t('statusComponent.noUserReview')"></span>
      </v-alert>
      <template v-else>
        <v-subheader class="font-weight-bold pt-4" style="height: auto">
         <span v-text="$t('statusComponent.userReviewed')"></span>
        </v-subheader>
        <v-list color="transparent">
          <v-list-item
            v-for="user in userList"
            :key="user.autoIncrementId"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="user.getFullName()"></v-list-item-title>
              <v-list-item-subtitle>
                (<span v-text="getProjectUserTypeName(user.data.userType)"></span>)
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-sheet>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Emit, ModelSync, Prop, Ref, Watch} from 'vue-property-decorator';
import RecordModel from '@/models/record.model';
import RecordService from '@/services/record.service';
import UserModel from '@/modules/sdk/models/user.model';
import Identity from '@/modules/sdk/core/identity';
import ManageRecordUserStatusModal from '@/components/ManageRecordUserStatusModal.vue';
import RecordUserStatusService from '@/services/record-user-status.service';
import RecordUserStatusModel from '@/models/record-user-status.model';
import StatusReasonModal from '@/views/Admin/Component/Record/StatusReasonModal.vue';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import AskQuestionToAIProjectModal from '@/components/AskQuestionToAIProjectModal.vue';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import {SnackError} from '@/modules/common/core/error';
import { projectUserTypeList } from '@/enums/global';
import RecordAiStatusModel from '@/models/record-ai-status.model';
import RecordAiStatusService from '@/services/record-ai-status.service';

interface IStatus {
  text: string,
  color: string,
  value: string,
}

@Component({
  components: {
    StatusReasonModal,
    ManageRecordUserStatusModal,
    AskQuestionToAIProjectModal,
  }
})
export default class RecordStatusComponent extends Vue {
  @ModelSync('vModel', 'change', {type: RecordModel}) record!: RecordModel;
  @Prop({default: () => false}) showUserList!: boolean;
  @Prop({default: () => false}) disabled!: boolean;
  @Prop({ default: () => 'screening' }) readonly stage!: 'screening' | 'indepth' | 'final' | string;
  @Prop() userType?: string;
  @Ref() readonly statusReasonModalRef!: StatusReasonModal

  userList: Array<UserModel> = [];
  selectedUser: string = Identity.identity?.user.id.toString();
  listLoading = false;
  saveLoading = false;
  reasonsLoaded = false;
  loadingAllReasons = false;
  loadingAiHistory = false;
  aiHistory: Array<any> = [];
  splitCommentPanels = [];
  askAiModal: {
    visible: boolean,
    record: RecordModel,
    stage: 'screening' | 'indepth' | 'final' | string,
  } = {
    visible: false,
    record: new RecordModel(),
    stage: 'screening',
  }

  status: any = {
    screening: 'pending',
    indepth: 'pending',
    final: 'pending',
  };

  allReasonList: {[key: string]: {
      items: Array<RecordUserStatusModel>,
      conflicting: boolean,
      equals: boolean,
      suggestion: IStatus | null,
  }} = {
    screening: {
      items: [],
      conflicting: false,
      equals: false,
      suggestion: null,
    },
    indepth: {
      items: [],
      conflicting: false,
      equals: false,
      suggestion: null,
    },
    final: {
      items: [],
      conflicting: false,
      equals: false,
      suggestion: null,
    },
  }

  statusReasons: any = {}
  originalStatus: any = {}

  statusList: Array<IStatus> = [
    {text: 'Pending', value: 'pending', color: 'warning'},
    {text: 'Halt', value: 'halt', color: 'error'},
    {text: 'Pass', value: 'pass', color: 'success'},
    // {text: 'Skip', value: 'skip', color: 'gray'},
  ];

  statusReasonModal: {
    visible: boolean,
    reason: string | null,
    stage: string | null,
  } = {
    visible: false,
    reason: null,
    stage: null,
  }

  viewRecordUserStatusModal: {
    visible: boolean,
    reasons: Array<RecordUserStatusModel>,
  } = {
    visible: false,
    reasons: [],
  }

  stageList = [
    {
      label: 'Screening Status',
      type: 'screening',
      loading: false,
    },
    {
      label: 'Indepth Status',
      type: 'indepth',
      loading: false,
    },
    {
      label: 'Final Status',
      type: 'final',
      loading: false,
    },
  ];

  @Watch('record.data.id', {
    immediate: true
  })
  onRecordIdChanged() {
    this.loadLastAiHistory();
  }

  @Watch('userType', {
    immediate: true
  })
  onUserTypeChanged(userType: string | undefined) {
    const splitUser: Array<string|undefined> = this.selectedUser.split('_');
    splitUser.length < 2 ? splitUser.push(userType) : splitUser[1] = userType;
    this.selectedUser = splitUser.join('_');

    this.loadRecordStatus(this.record.data.id, this.selectedUser, userType);

    if (['arbitrator', 'leader'].includes(userType || '')) {
      this.loadAllReasons()
        .then(reasons => {
          ['screening', 'indepth', 'final'].forEach(type => {
            const items = reasons.filter(reason => reason.data.type === type);
            const statuses = items.map(item => item.data.status);
            const uniqueStatuses = statuses.filter((item, index) => statuses.indexOf(item) === index);

            this.allReasonList[type] = {
              conflicting: uniqueStatuses.length > 1,
              equals: uniqueStatuses.length === 1 && reasons.length >= 1,
              suggestion: null,
              items,
            };

            if (this.allReasonList[type].equals) {
              this.allReasonList[type].suggestion = this.statusList.find(status => status.value === this.allReasonList[type].items[0].data.status) || null;
            }

            // If more than two answer with the same value, set it as default in the field
            const counts: {[key: string]: {
                count: number,
                status: any
              }} = {};
            items.forEach(item => {
              if (!counts[item.data.id]) {
                counts[item.data.id] = {
                  count: 0,
                  status: item.data.status,
                };
              }
              if (counts[item.data.id].status === item.data.status) {
                counts[item.data.id].count++;
              }
            })
          })
        });
    }
  }

  onAskQuestionAi(): void {
    Object.assign(this.askAiModal, {
      visible: true,
      record: this.record,
      stage: this.stage,
    });
  }

  onViewAnswersClick(reasons: Array<RecordUserStatusModel>): void {
    Object.assign(this.viewRecordUserStatusModal, {
      visible: true,
      reasons,
    })
  }

  onStatusReasonConfirm(reason: string | null) {
    const stage = this.stageList.find(stage => stage.type === this.statusReasonModal.stage);
    if (stage) {
      this.saveStatus(stage, this.record, reason);
    }
  }

  onStatusReasonCancel() {
    this.revertToOriginalStatusList();
  }

  get canManageRecordUserStatus(): boolean {
    return ['arbitrator', 'leader'].includes(this.userType || '');
  }

  get canChangeStatus(): boolean {
    const user = this.userList.find(user => {
      return (
        (user.data.userType === 'researcher' && this.userType === 'researcher')
        || (user.data.userType === 'secondary-researcher' && this.userType === 'secondary-researcher')
      )
    });

    return (
      (this.getSelectedUserId() === Identity.identity?.user.id && (!user || user.data.id === Identity.identity?.user.id)) || !user
    ) && this.userType !== 'leader';
  }

  getSelectedUserId(selectedUser = this.selectedUser): number {
    return parseInt(selectedUser.split('_')[0]);
  }

  get _stageList(): Array<any> {
    return this.record.data.id && (this.stageList.filter(stage => this.showStage(stage.type)) || []);
  }

  getUserListItemValue(item: UserModel) {
    return item.data.id + '_' + item.data.userType;
  }

  onAiQuestionApply(status: RecordAiStatusModel): Promise<any> {
    return this.saveStatus(
      this.stageList.find(item => item.type === this.stage),
      this.record,
      undefined,
      undefined,
      status.data.status,
    )
  }

  onApplyStatusReason(reason: ProjectStatusReasonModel): void {
    this.saveStatus(
      this.stageList.find(item => item.type === reason.data.type),
      this.record,
      reason.data.status,
      this.userType,
    )
      .then(() => {
        this.loadAllReasons();
      })
  }

  onEditReasonOfExclusionClick(stage: any) {
    Object.assign(this.statusReasonModal, {
      visible: true,
      reason: this.statusReasons[stage.type],
      stage: stage.type,
    });
  }

  /**
   * Show the status depending on the record state
   * @param status
   */
  showStage(status: 'screening' | 'indepth' | 'final' | string) {
    if (status === 'screening') {
      return true;
    }
    else if (status === 'indepth') {
      if (this.record.data.screeningStatus === 'pass') {
        return true;
      }
    }
    else if (status === 'final') {
      if (this.record.data.screeningStatus === 'pass' && this.record.data.indepthStatus === 'pass') {
        return true;
      }
    }
    return false;
  }

  /**
   * User ID
   */
  onUserChange(selectedUser: string) {
    const splitUser: Array<string|undefined> = this.selectedUser.split('_');

    this.loadRecordStatus(this.record.data.id, selectedUser, splitUser[1]);
  }

  /**
   * Clear Status
   */
  clearStatus() {
    this.status.screening = 'pending';
    this.status.indepth = 'pending';
    this.status.final = 'pending';
  }

  getStatus(status: string) {
    return this.statusList.find((item: any) => item.value === status);
  }

  splitComment(comment: string) {
    const splitComments = comment.split(/\[\[([^\]]+)\]\]/gim).map(item => item.trim()).filter(item => item !== '');
    if (splitComments.length === 1) {
      return comment;
    }

    const sections = [];
    let section: any = {};
    for (let i = 0; i < splitComments.length; i++) {
      const splitComment = splitComments[i];
      if (i % 2 === 0) {
        section = {};
        section.title = splitComment;
      } else {
        section.body = splitComment;
        sections.push(section);
      }
    }
    return sections;
  }

  clearUserList() {
    this.userList = [];
  }

  saveOriginalStatusList() {
    this.originalStatus = structuredClone(this.status);
  }

  revertToOriginalStatusList() {
    this.status = structuredClone(this.originalStatus);
  }

  getProjectUserTypeName(type: string): string {
    const item = projectUserTypeList.find(item => item.value === type);
    return item?.text || type;
  }

  onRatingChange(answer: RecordAiStatusModel): void {
    answer.states.saving = true;
    RecordAiStatusService.getInstance().save({
      id: answer.data.id,
      rating: answer.data.rating,
    })
      .then(response => answer.assign(response.data.view.single))
      .finally(() => answer.states.saving = false);
  }

  loadAllReasons(): Promise<Array<RecordUserStatusModel>> {
    this.loadingAllReasons = true;
    return RecordUserStatusService.getInstance().getAll({
      filters: [{
        field: 'recordId',
        operator: 'equals',
        value: this.record.data.id,
      }]
    })
      .then(response => {
        ['screening', 'indepth', 'final'].forEach(type => {
          this.allReasonList[type].items = response.data.view.list.filter((item: RecordUserStatusModel) => item.data.type === type);
        })

        this.reasonsLoaded = true;
        return response.data.view.list;
      })
      .catch(this.$root.$zemit.handleError)
      .finally(() => this.loadingAllReasons = false);
  }

  async loadLastAiHistory(): Promise<any> {
    this.loadingAiHistory = true;
    return RecordAiStatusService.getInstance().getAll({
      limit: 1,
      order: 'id DESC',
      filters: [{
        field: 'recordId',
        operator: 'equals',
        value: this.record.data.id,
      }]
    })
      .then(response => this.aiHistory = response.data.view.list)
      .finally(() => this.loadingAiHistory = false);
  }

  /**
   * Load Record Status from selected user
   */
  loadRecordStatus(
    recordId: number = this.record.data.id,
    selectedUser: string = this.selectedUser,
    userType = this.userType,
  ) {
    this.listLoading = true;
    this.clearStatus();

    const filters = [
      {field: 'recordId', value: recordId, operator: 'equals'},
      {field: 'deleted', value: 0, operator: 'equals'},
    ];

    RecordUserStatusService.getInstance().getAll({filters})
      .then((response) => {
        if (!response.data.view.list) {
          throw new SnackError({
            title: 'Unable to load user status',
            message: 'Record user status list is empty'
          })
        }

        // User status list found, clear user list
        this.clearUserList();
        response.data.view.list.forEach((recordUserStatus: RecordUserStatusModel) => {

          // Populate the user list for admins
          if (!this.userList.find((user: UserModel) => {
            return user.data.id === recordUserStatus.data.userentity?.data.id
              && recordUserStatus.data.userType === user.data.userType
          })) {
            this.userList.push(new UserModel({
              ...recordUserStatus.data.userentity?.data || {
                firstName: 'AI',
              },
              userType: recordUserStatus.data.userType,
            }));
          }

          // Set the status for the requested user
          if (recordUserStatus.data.userId === this.getSelectedUserId(selectedUser) && recordUserStatus.data.userType === userType) {
            this.status[recordUserStatus.data.type] = recordUserStatus.data.status;
            this.statusReasons[recordUserStatus.data.type] = recordUserStatus.data.projectstatusreasonentity?.data?.label;
          }
        });

        this.saveOriginalStatusList();
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.listLoading = false);
  }

  /**
   * Save the status
   */
  @Emit()
  async saveStatus(
    stage: any,
    record: RecordModel = this.record,
    reason?: ProjectStatusReasonModel | string | null,
    userType = this.userType,
    forceStatus?: string,
  ): Promise<any> {
    const status = forceStatus || this.status[stage.type];
    this.status[stage.type] = status;

    const saveReasonStatus = status === 'halt' && stage.type !== 'screening';
    if (reason === undefined && saveReasonStatus) {
      stage.loading = true;
      return this.statusReasonModalRef.load()
        .then((response) => {
          if (!response.data.view.list) {
            throw new SnackError({
              title: 'The status has not been updated',
              message: 'Unable to retrieve status reason list'
            });
          }
          // Open Status Reason Modal
          Object.assign(this.statusReasonModal, {
            visible: true,
            reason: null,
            stage,
          });
        })
        .catch(reason => {
          this.saveStatusFailed();
          this.$root.$zemit.handleError(reason);
        })
        .finally(() => stage.loading = false);
    }
    else {

      const callback = async (data: any, reasonLabel?: string | null) => {
        return RecordService.getInstance().saveUserStatus(data, {
          params: {
            userType,
          }
        })
          .then((response) => {
            if (!response.data.view.saved || !response.data.view.single) {
              throw new SnackError({
                title: 'The status has not been updated',
                message: 'An unexpected error has occurred'
              });
            }

            // Success
            record.assign(response.data.view.single);
            this.$root.$globalSnack.success({
              title: 'Record status has been successfully updated',
              message: 'The `' + stage.type + '` stage is now set to `' + status + '`'
            });
            this.saveStatusSuccess();
            this.statusReasonModalRef.close();
            this.statusReasons[stage.type] = reasonLabel || null;
          })
          .catch(reason => {
            this.saveStatusFailed();
            this.$root.$zemit.handleError(reason)
          })
          .finally(() => {
            this.saveLoading = false;
            stage.loading = false;
          });
      }

      stage.loading = true;
      this.saveLoading = true;

      // Record data to save
      const data: any = {
        id: record.data.id
      };
      data[stage.type + 'Status'] = status;

      if (!saveReasonStatus) {
        data[stage.type + 'ProjectStatusReasonId'] = null;
        return callback(data);
      }
      else if (reason instanceof ProjectStatusReasonModel) {
        data[stage.type + 'ProjectStatusReasonId'] = reason.data.id;
        return callback(data, reason.data.label);
      }
      else {
        const model = new ProjectStatusReasonModel({
          projectId: record.data.projectId,
          label: reason,
          status,
          stage: stage.type,
        });

        return ProjectStatusReasonService.getInstance().save(model)
          .then(response => {
            // error
            if (!response.data.view.saved || !response.data.view.single) {
              throw new SnackError({
                title: 'The status reason has not been saved',
                message: 'An unexpected error has occurred'
              });
            }
            // success
            data[stage.type + 'ProjectStatusReasonId'] = response.data.view.single.data.id;
            callback(data, reason);
          })
          .catch(reason => {
            this.$root.$zemit.handleError(reason);
            this.saveLoading = false;
            stage.loading = false;
          });
      }
    }
  }

  @Emit()
  saveStatusSuccess() {
  }

  @Emit()
  saveStatusFailed() {
  }
}
</script>
