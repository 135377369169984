<template>
  <ModalDialog v-model="_visible" v-bind="$attrs" v-on="$listeners" background-color="background">
    <template #body>
      <div class="mt-4">
        <v-row :key="shortcutIdx" v-for="(shortcut, shortcutIdx) in shortcuts">
          <v-col cols="12" md="4" class="d-flex align-center">
            <v-chip label v-text="shortcut.key.toUpperCase()"></v-chip>
          </v-col>
          <v-col cols="12" md="8" class="d-flex align-center">
            <div v-text="$t(shortcut.i18n)"></div>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #buttons>
      <v-btn outlined @click="_visible = false">
        <span v-text="$t('btn.close')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, PropSync} from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';

@Component({
  components: {
    ModalDialog,
  }
})
export default class ShortcutDialog extends Vue {
  @PropSync('visible', { default: false }) _visible!: boolean;
  @Prop({ default: () => ([]) }) shortcuts!: Array<any>;
}
</script>
